type ButtonProps = {
  children?: React.ReactNode;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, className, onClick, type, disabled }) => {
  return (
    <button
      className={`w-full rounded-3xl p-3 bg-brand text-white font-medium text-xs ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
