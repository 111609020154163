import { ReactComponent as LogoDark } from '../assets/icons/logo-full-light.svg';

function MainSplash(): JSX.Element {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        margin: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoDark width={300} height={300} />
    </div>
  );
}

export default MainSplash;
