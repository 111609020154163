import { CSSProperties, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import { v4 } from 'uuid';
import { queries } from '../../../devices';
import './styles.css';
SwiperCore.use([Navigation, Thumbs]);

type SliderProps = Swiper & {
  images: string[];
};

function Slider(props: SliderProps): JSX.Element {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const isLarge = useMediaQuery({
    query: queries.xl,
  });

  return (
    <div className={props.className}>
      <Swiper
        style={
          {
            '--swiper-navigation-color': '#DA291C',
            '--swiper-pagination-color': '#1B1717',
            '--swiper-navigation-size': '24px',
          } as CSSProperties
        }
        // spaceBetween={10}
        navigation={isLarge}
        pagination={!isLarge}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        className="mySwiper2 !h-[400px]"
      >
        {props.images.map((image) => {
          return (
            <SwiperSlide key={v4()}>
              <img src={image} className="!object-contain" />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {isLarge ? (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesVisibility
          watchSlidesProgress
          className="mySwiper"
        >
          {props.images.map((image, index) => {
            return (
              <>
                <SwiperSlide key={v4()} style={{ padding: '0 2rem' }}>
                  <img src={image} height="82px" width="82px" />
                </SwiperSlide>
              </>
            );
          })}
        </Swiper>
      ) : (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesVisibility
          watchSlidesProgress
          className="mySwiper hidden"
        >
          {props.images.map((image, index) => {
            return (
              <>
                <SwiperSlide key={v4()} style={{ padding: '0 2rem' }}>
                  <img src={image} height="82px" width="82px" />
                </SwiperSlide>
              </>
            );
          })}
        </Swiper>
      )}
    </div>
  );
}

export default Slider;
