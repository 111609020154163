import { default as dayjs } from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { isRtl } from '../../..';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { Software } from '../../../types/Device';

type AccordionPageProps = {
  software: Software | null;
};
export const AccordionPage = ({ software }: AccordionPageProps): JSX.Element => {
  return (
    <Accordion>
      {software &&
        software !== undefined &&
        software.versions.map((version) => {
          return (
            <div className="border border-gray-200 rounded-2xl mt-2 " key={version?._id} dir={isRtl ? 'rtl' : 'ltr'}>
              <AccordionItem toggle={version._id}>
                <div className="flex w-full items-center">
                  <span>{version?.number}</span>
                </div>
              </AccordionItem>
              <AccordionPanel id={version._id}>
                <div className="mt-4 flex flex-col xl:flex-row">
                  <a
                    className="bg-linkColor hover:bg-linkColorHover text-white py-2 px-5 rounded-3xl text-sm w-full sm:w-8/12 md:w-6/12 xl:!w-6/12 mr-2 flex text-center justify-center transition"
                    href={version.fileURL}
                  >
                    {i18n.t('software.downloadBuild')}
                  </a>
                  {version.howToUpdate && (
                    <button className=" py-1 text-sm flex justify-center border border-solid border-brand text-linkColorHover hover:bg-brand hover:text-white rounded-3xl mt-5 xl:py-1 xl:mt-0 px-6 items-center transition">
                      <Link to={`/how-to-use?videoId=${version.howToUpdate?._id}`}>
                        {i18n.t('software.howToUpdate')}
                      </Link>
                    </button>
                  )}
                </div>
                <div className="mt-8 pl-2">
                  <div>
                    <span className="text-red-700 font-bold mr-2">{i18n.t('software.name')}</span>
                    {version.number}
                  </div>
                  <p className="my-2">
                    <span className="text-red-700 font-bold mr-2 ">{i18n.t('software.version')}</span>
                    {version?.fileName}
                  </p>
                  <div className="my-2">
                    <span className="text-red-700 font-bold mr-2">{i18n.t('software.date')}</span>
                    {dayjs(new Date(version.updatedAt)).format('MMM D, YYYY h:mm A')}
                  </div>
                </div>
                <div className="mt-12 flex flex-col h-full pl-2">
                  <span className="font-semibold">{i18n.t('software.changelog')}</span>
                  <div className="bg-cardGray rounded-2xl mt-5 h-96">
                    <ul className="m-5 semibold h-96">
                      {getValueFromLang(version.changelog)
                        .split('-')
                        .map((change) => (
                          <li key={v4()}> - {change}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </AccordionPanel>
            </div>
          );
        })}
    </Accordion>
  );
};
/* Logic */
const Context = React.createContext({});
function Accordion({ children }: any) {
  const [selected, setSelected] = React.useState('');
  const toggleItem = React.useCallback(
    (id) => () => {
      setSelected((prevState) => (prevState !== id ? id : ''));
    },
    [],
  );
  return <Context.Provider value={{ selected, toggleItem }}>{children}</Context.Provider>;
}
//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);
const style = {
  item: `block focus:outline-none  text-black  rounded-xl  w-full w-full text-sm `,
  panel: `overflow-hidden md:overflow-x-hidden transition-height ease duration-300 text-gray-600 mt-4 `,
};
function AccordionItem({ toggle, children }: any) {
  const { selected, toggleItem }: any = useAccordion();
  return (
    <button role="button" onClick={toggleItem(toggle)} className={`${style.item} `}>
      <div
        className={`flex items-center ${selected === toggle ? 'my-2 p-4 px-6 ' : 'my-0 p-4 px-6 sm:px-8  !sm:pb-0'}`}
      >
        <span className="mr-3">{selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}</span>

        {children}
      </div>
    </button>
  );
}
function AccordionPanel({ children, id }: any) {
  const { selected }: any = useAccordion();
  const ref: any = React.useRef();
  const inlineStyle = selected === id ? { height: ref.current?.scrollHeight + 40 } : { height: 0 };
  return (
    <div
      ref={ref}
      id={id}
      className={style.panel + ` ${selected === id ? 'rounded-xl p-4 px-8 ' : 'p-0 m-0 !mt-0'}`}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}

const AngleUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.954" height="8.391" viewBox="0 0 13.954 8.391">
    <path
      id="arrow-down"
      d="M895.556-67.839l6.27,6.27,6.27-6.27"
      transform="translate(-894.848 68.547)"
      fill="none"
      stroke="#ce1212"
      strokeWidth="2"
    />
  </svg>
);
const AngleDownIcon = () =>
  isRtl ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.391" height="13.954" viewBox="0 0 8.391 13.954">
      <path
        id="arrow-left"
        d="M895.556-67.839l6.27,6.27,6.27-6.27"
        transform="translate(68.547 908.803) rotate(-90)"
        fill="none"
        stroke="#ce1212"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.391" height="13.954" viewBox="0 0 8.391 13.954">
      <path
        id="arrow-right"
        d="M895.556-67.839l6.27,6.27,6.27-6.27"
        transform="translate(68.547 908.803) rotate(-90)"
        fill="none"
        stroke="#ce1212"
        strokeWidth="2"
      />
    </svg>
  );
