import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { ReactComponent as SoftwareIllustration } from '../../../assets/icons/illustrations/software-illustration.svg';
import { ReactComponent as OutLink } from '../../../assets/icons/outlink-no-circle.svg';
import { queries } from '../../../devices';
import i18n from '../../../i18n';

const IntroSection = (): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });
  return (
    <div className="bg-lightGray w-full flex flex-col xl:flex-row rounded-3xl xl:mt-20 container">
      {isLarge ? (
        <section className="flex flex-col justify-between items-center xl:flex-row w-full    p-24">
          <SoftwareIllustration className="xl:order-2 xl:h-[310px] xl:flex-1" />
          <div className="flex flex-col xl:flex-1">
            <h1 className="text-2xl font-semibold mt-16 xl:mt-0 text-main text-center xl:text-left xl:text-4xl  xl:w-[350px]">
              {i18n.t('software.title')}
            </h1>
            <p className="text-main mt-5 text-left  w-[390px] hidden xl:block">{i18n.t('software.subtitle')}</p>
            <div className="text-main mt-4 hidden xl:block">
              <Link
                to="/contact"
                className=" ml-1 items-center  xl:inline-flex text-linkColor hover:text-linkColorHover  transition"
              >
                <span className="mr-3 ">{i18n.t('software.howToUpdate')}</span>
                <OutLink
                  className="-mt-1 inline text-linkColor hover:text-linkColorHover stroke-current icon"
                  width={12}
                  height={12}
                />
              </Link>
            </div>{' '}
          </div>
        </section>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <SoftwareIllustration className="w-10/12   h-6/12 xl:h-6/23 xl:w-6/12 mt-3 md:w-6/12 xl:w-5/12" />
          <h1 className="text-2xl font-semibold mt-16 text-main text-center xl:text-left xl:text-4xl  xl:w-[350px]">
            {i18n.t('software.howToUpdate')}
          </h1>
        </div>
      )}
    </div>
  );
};

export default IntroSection;
