import queryString from 'query-string';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetDevicesQuery } from '../../../services/api';
import SEO from '../../SEO';
import Dropdown from '../../UI/Dropdown';
import Input from '../../UI/Input/Input';
import IntroSection from './IntroSection';
import ItemsSection from './ItemsSection';

const Software = (): JSX.Element => {
  const [searchedVal, setSearchedVal]: [string, Dispatch<SetStateAction<string>>] = useState('');
  const {
    data: devices,
    isLoading: devicesIsLoading,
    isSuccess: devicesIsSucess,
    isError: devicesIsError,
  } = useGetDevicesQuery('');

  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchedVal(e.target.value);
  };

  const devicesDropdown = devices?.map((device) => {
    return {
      key: device._id,
      text: getValueFromLang(device.name),
    };
  });

  devicesDropdown?.unshift({
    key: '',
    text: i18n.t('software.allDevices'),
  });

  const handleDropdownClick = (id: string) => {
    query.deviceId = id;

    history.push({
      pathname: '/software',
      search: queryString.stringify(query),
    });
  };

  useEffect(() => {
    if (query.deviceId?.toString() === undefined) {
      query.deviceId = '';
      history.push({
        pathname: '/software',
        search: queryString.stringify(query),
      });
    }
  }, [query.deviceId?.toString()]);

  return (
    <>
      <SEO
        title="Mediastar - Software"
        description="To stay on top of the latest technologies, update your software regularly. Check out the link below to find out how to update your Mediastar device software."
        keywords={['software update', 'device software', 'frameware update']}
      />
      <div className="flex justify-center flex-col items-center bg-lightGray xl:bg-white">
        <IntroSection />
        <div className="container mt-4">
          <div className="bg-lightGray w-full flex flex-col p-6 items-center  xl:bg-white ">
            <div className="w-full flex flex-col justify-center h-full mt-4 xl:mt-0">
              <span className="w-full relative xl:hidden">
                <SearchIcon className="absolute right-5 top-3" />
                <Input
                  placeholder={i18n.t('product.searchInput.placeholder')}
                  value={searchedVal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
                    onChange(e)
                  }
                  className="!rounded-3xl mb-5 md:w-full p-3 text-sm px-4"
                  name="search"
                />
              </span>
              {devices && devices !== undefined && (
                <Dropdown
                  getSelectedKey={(key) => handleDropdownClick(key)}
                  hasIcon={false}
                  items={devicesDropdown !== undefined ? devicesDropdown : []}
                  dropdownButtonClassName={
                    'w-12/12 !bg-white border border-solid border-gray-300 !h-10 text-sm xl:hidden'
                  }
                  dropdownClassName={'w-12/12 xl:hidden'}
                  dropdownItemClassName={'!bg-white xl:hidden'}
                  dropdownItemsClassName={'!bg-white xl:hidden'}
                />
              )}
            </div>
          </div>

          {devices !== undefined && <ItemsSection devices={devices} />}
        </div>
      </div>
    </>
  );
};

export default Software;
1;
