import { motion } from 'framer-motion';
import Highlighter from 'react-highlight-words';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { isRtl } from '../../..';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow-circle.svg';
import { ReactComponent as OutLink } from '../../../assets/icons/out-link.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social-media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/social-media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/social-media/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/social-media/youtube.svg';
import Gradient from '../../../assets/images/gradient.png';
import Pheonix from '../../../assets/images/Pheonix.png';
import { queries } from '../../../devices';
import i18n from '../../../i18n';

function HeroSection(): JSX.Element {
  const titleVariants = {
    visible: isRtl ? { x: 0, opacity: 1 } : { x: 0, opacity: 1 },
    hidden: isRtl ? { x: '100%', opacity: 0 } : { x: '-100%', opacity: 0 },
  };
  const isMedium = useMediaQuery({
    query: queries.md,
  });
  const heroTitle = i18n.t('home.hero.title');
  return (
    <>
      <div className="flex flex-col justify-center px-6 pb-0 xl:px-2 bg-black  container xl:h-screen items-center xl:-mt-16">
        <div className="flex flex-col xl:flex-row justify-center px-6 pb-0 xl:px-2 bg-black ">
          <motion.div
            className="flex flex-col xl:items-start xl:flex-1 "
            animate={'visible'}
            initial={'hidden'}
            variants={titleVariants}
            transition={{ duration: 1.8, ease: 'easeInOut' }}
          >
            <p className="text-center xl:px-2 text-sm">
              <span className="font-semibold text-offWhite">{i18n.t('common.mediastar')}</span>{' '}
              <span className="text-beige text-opacity-70">{i18n.t('common.deviceSeries').toLowerCase()}</span>
            </p>
            <h1 className=" text-offWhite text-4xl font-bold colorful-text text-center xl:text-left xl:text-7xl  mt-6 px-6 xl:px-2">
              <Highlighter
                highlightStyle={{
                  backgroundImage: `url(${Gradient})`,
                  backgroundPosition: isMedium ? '0% 90%' : '0% 50%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                }}
                searchWords={[heroTitle.split(' ')[heroTitle.split(' ').length - 1]]}
                autoEscape={true}
                textToHighlight={heroTitle}
              />
            </h1>
            <motion.p
              className="text-beige text-opacity-70 text-center  xl:text-left mt-6 font-light px-6 xl:px-2"
              animate={isRtl ? { x: 0, opacity: 1 } : { x: 0, opacity: 1 }}
              initial={isRtl ? { x: '+100%', opacity: 0 } : { x: '-100%', opacity: 0 }}
              transition={{ duration: 1.8, ease: 'easeInOut' }}
            >
              {i18n.t('home.hero.subtitle')}
            </motion.p>

            <div className="flex xl:flex w-full xl:w-full items-center mt-7">
              <Link
                to="/product"
                className="bg-offWhite rounded-full text-main px-8 py-2 self-center mx-auto xl:mx-0 hover:bg-offWhiteHover transition"
              >
                {i18n.t('common.buyNow')}
              </Link>
              <Link
                to="/about-us"
                className="text-offWhite ml-7 items-center hidden xl:flex hover:offWhiteHover transition"
              >
                <span className="mr-3  ">{i18n.t('common.learnMore')}</span>
                <OutLink className="icon" />
              </Link>
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col items-center justify-center"
            style={{ flex: '2' }}
            animate={isRtl ? { x: 0, opacity: 1 } : { x: 0, opacity: 1 }}
            initial={isRtl ? { x: '-100%', opacity: 0 } : { x: '100%', opacity: 0 }}
            transition={{ duration: 2, ease: 'easeInOut' }}
          >
            <div className="xl:-right-1/2 hidden xl:flex  w-full relative left-14 xl:top-1/4">
              <div className="relative w-full h-full -right-1/2">
                <div className={`${isRtl ? 'overlay-rtl' : 'overlay'} xl:absolute`}></div>

                <img src={Pheonix} alt="Pheonix" width="100%" className="" />
              </div>
              <p>{i18n.t('common.findUs')} --</p>
            </div>

            <div className="relative mt-14">
              <div className={`${isRtl ? 'overlay-rtl' : 'overlay'} absolute`}></div>
              <img src={Pheonix} alt="Pheonix" height="auto" className="xl:hidden" />
            </div>

            <div className="flex items-center text-sm self-center xl:hidden mt-24">
              <DownArrow width={16} height={16} />
              <ScrollLink className="text-gray-300 ml-4" spy={true} smooth={true} duration={1000} to="devicesSection">
                {i18n.t('home.hero.scrollForMore')}
              </ScrollLink>
            </div>
          </motion.div>
        </div>
        <motion.div
          className="flex flex-row justify-between w-full container items-center mt-10 lg:mt-52"
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: '300%', opacity: 0 }}
          transition={{ delay: 1.5, duration: 1, ease: 'easeInOut' }}
        >
          <div className="items-center text-sm self-center mr-auto  hidden xl:flex">
            <DownArrow width={16} height={16} />
            <ScrollLink
              className="text-gray-300 ml-4 hover:text-gray-400 cursor-pointer transition"
              spy={true}
              smooth={true}
              duration={1000}
              to="devicesSection"
            >
              {i18n.t('home.hero.scrollForMore')}
            </ScrollLink>
          </div>
          <div className="text-white hidden xl:flex items-center">
            <span>{i18n.t('common.findUs')}</span>
            <span className="ml-4">&mdash;</span>
            <a href="https://www.facebook.com/Mediastarofficial/" className="ml-4" target="_blank" rel="noreferrer">
              <FacebookIcon className=" text-white hover:text-brand fill-current transition" />
            </a>
            <a href="https://www.instagram.com/mediastariraq/" className="ml-4" target="_blank" rel="noreferrer">
              <InstagramIcon className=" text-white hover:text-brand fill-current transition" />
            </a>
            <a href="https://t.me/MediaStar" className="ml-4" target="_blank" rel="noreferrer">
              <TwitterIcon className=" text-white hover:text-brand fill-current transition" />
            </a>
            <a href="https://youtube.com" className="ml-4" target="_blank" rel="noreferrer">
              <YoutubeIcon className=" text-white hover:text-brand fill-current transition" />
            </a>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default HeroSection;
