import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import i18n from '../../../i18n';
import { Category } from '../../../types/Product';
type ProductCardProps = {
  thumbnail: string;
  id: string;
  name: string;
  isNew: boolean;
  categoryId: Category;
};

function ProductCard({ thumbnail, id, isNew, categoryId, name }: ProductCardProps): JSX.Element {
  return (
    <div className="bg-white px-0 sm:px-4  py-6 flex flex-col relative items-center justify-center rounded-3xl   xl:px-2 ">
      {isNew && <span className="font-semibold text-sm text-brand absolute  top-6 left-6">{i18n.t('common.new')}</span>}
      <LazyLoadImage
        src={thumbnail}
        className="h-[90px] px-4 object-contain md:h-[140px]  xl:h-[200px] mt-6 sm:mt-4 xl:px-12"
        effect="blur"
      />
      <h2 className="font-medium text-base px-4 sm:px-0 xl:text-lg text-main min-h-[30px] sm:min-w-[130px]  xl:max-w-[400px] text-center xl:center  mt-4">
        {name}
      </h2>
      <Link
        to={`/product/${id}`}
        className="text-linkColor my-6 flex items-center text-sm hover:text-linkColorHover transition"
      >
        <span className="xl:text-linkColor mr-1 text-sm">{i18n.t('howToUse.learnMore')}</span>{' '}
        <Caret className="stroke-current text-linkColor xl:text-linkColor icon" width={10} height={10} />
      </Link>
    </div>
  );
}

export default ProductCard;
