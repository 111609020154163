import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import i18n from '../../../i18n';

export type FilterType = {
  _id: string;
  text: string;
  isParent: boolean;
  parentId: string | null | undefined;
};
export type FilterProps = {
  type: string;
  filters: FilterType[];
  isLast: boolean;
  id: string;
  onChange: (data: FilterType) => void;
};

function Filter({ id, type, filters, onChange, isLast }: FilterProps): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const query = queryString.parse(location.search);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    filters &&
      filters.length > 0 &&
      filters.forEach((filter) => {
        const isChecked = filter.isParent
          ? filter._id === query.parentId?.toString().trim()
          : filter._id === query.categoryId?.toString().trim();

        if (isChecked) {
          setIsCollapsed(false);
        }
      });
  }, [query.parentId?.toString().trim(), query.categoryId?.toString().trim(), isCollapsed]);

  return (
    <div className="my-4 flex flex-col">
      <button className="flex justify-between items-center mb-6" onClick={toggle}>
        <span className="font-medium">{type}</span>
        {isCollapsed ? (
          <PlusIcon height={16} width={16} className="fill-current text-gray-400" />
        ) : (
          <MinusIcon width={16} height={2} className="stroke-current text-gray-400" />
        )}
      </button>

      <div className={isCollapsed ? 'hidden' : 'flex flex-col'}>
        <label className="inline-flex items-center mb-4" key={uuid()}>
          <input
            type="radio"
            onChange={() =>
              onChange({ _id: id, isParent: true, parentId: 'all', text: i18n.t('product.allCategories') })
            }
            name="filter"
            checked={id === query.parentId?.toString().trim()}
            className="form-radio  border-gray-300 text-brand shadow-sm focus:border-gray-400 focus:ring focus:ring-offset-0 focus:ring-brand focus:ring-opacity-20"
          />
          <span className="ml-4 text-main text-opacity-80 text-sm xl:text-base">{i18n.t('product.allCategories')}</span>
        </label>
        {filters !== undefined &&
          filters.length > 0 &&
          filters.map((filter) => {
            const isChecked = filter.isParent
              ? filter._id === query.parentId?.toString().trim()
              : filter._id === query.categoryId?.toString().trim();
            return (
              <label className="inline-flex items-center mb-4" key={uuid()}>
                <input
                  type="radio"
                  onChange={() => onChange(filter)}
                  name="filter"
                  checked={isChecked}
                  className="form-radio  border-gray-300 text-brand shadow-sm focus:border-gray-400 focus:ring focus:ring-offset-0 focus:ring-brand focus:ring-opacity-20"
                />
                <span className="ml-4 text-main text-opacity-80 text-sm xl:text-base">{filter.text}</span>
              </label>
            );
          })}
      </div>

      {!isLast && <hr className={`text-gray-200 ${!isCollapsed && 'mt-4'}`} />}
    </div>
  );
}

export default Filter;
