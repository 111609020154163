import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import { useGetProductQuery } from '../../../services/api';
import SEO from '../../SEO';
import { Loader } from '../../UI/Loader';
import Feature from './Feature';
import GetInTouchSection from './GetInTouchSection';
import OverView from './OverView';
import RelatedProductsSection from './RelatedProductsSection';

const ProductDetails = (): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  const params = useParams<{ id: string } | undefined>();

  const { data: product, isLoading, isSuccess, isError } = useGetProductQuery(params?.id ? params?.id : '');

  return (
    <>
      <SEO
        title={getValueFromLang(product?.title)}
        description={getValueFromLang(product?.description)}
        keywords={[
          'mediastar',
          getValueFromLang(product?.category.name),
          getValueFromLang(product?.category?.parent?.name),
          getValueFromLang(product?.name),
        ]}
      />
      {!isLarge && (
        <div className="flex justify-center xl:justify-start text-sm bg-lightGray w-full xl:bg-white p-5 xl:w-10/12 xl:border-b  items-center mb-16">
          <a href="#">FEATURES</a>
          <a href="#" className="px-8">
            OVERVIEW
          </a>
          <a href="#">RELATED</a>
        </div>
      )}
      <div className={`container ${isLoading && 'flex justify-center items-center'}`}>
        {isLoading || product === undefined ? (
          <Loader isLoading={true} />
        ) : (
          <>
            <div className="w-full flex">
              {isLarge && (
                <div className="flex justify-center xl:justify-start text-sm bg-lightGray w-full xl:bg-white py-6 xl:border-b border-gray-200 items-center">
                  <span className="text-gray-500">
                    HOME / Product / {getValueFromLang(product?.category?.name)} /{' '}
                    {getValueFromLang(product?.category?.parent?.name)} /{' '}
                    <span className="font-medium text-main">{getValueFromLang(product?.name)}</span>
                  </span>
                </div>
              )}
            </div>
            <div className="w-full  flex justify-center">
              <OverView product={product} />
            </div>
            <div className="w-full flex justify-center flex-col text-center mt-32 container">
              <h2 className="text-3xl font-bold xl:text-4xl xl:w-2/5 mx-auto">{getValueFromLang(product?.title)}</h2>
              <div className="flex justify-center">
                <div className="grid grid-cols-3 w-full xl:grid-cols-5 gap-x-6 gap-y-16 lg:gap-20  mt-20 lg:mt-48 xl:w-6/12  ">
                  {product.features.map((feature) => {
                    return (
                      <div key={feature._id} className="flex justify-center items-center w-full flex-col xl:w-24 ">
                        <img src={feature.icon} width="32" height="32" />
                        <span className="text-sm mt-5">{feature.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center flex-col  pt-20 text-center px-5 xl:px-0">
              {product.body.map((b, i) => {
                return (
                  <Feature
                    key={i}
                    isOdd={i % 2 !== 0 ? true : false}
                    img={b.image}
                    title={getValueFromLang(b.title)}
                    description={getValueFromLang(b.description)}
                  />
                );
              })}
            </div>
            <div className="w-full flex justify-center bg-lightGray xl:bg-white pt-8">
              <RelatedProductsSection />
            </div>
            <div className="flex justify-center">
              <GetInTouchSection />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProductDetails;
