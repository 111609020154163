import { convertCategoriesToFilters } from '../../../helpers';
import { Category } from '../../../types/Product';
import Filter, { FilterType } from './Filter';

type FiltersProps = {
  className?: string;
  categories: Category[];
  onFilterChange: (data: FilterType) => void;
};

export type FilterCategory = {
  type: string;
  _id: string;
  filters: FilterType[];
};

function Filters({ className, categories, onFilterChange }: FiltersProps): JSX.Element {
  const filters = convertCategoriesToFilters(categories);

  return (
    <aside className={`bg-white p-6 rounded-3xl my-6 ${className}`}>
      {filters.map((filter, i) => {
        const isLast = i === filters.length - 1;
        return (
          <>
            <Filter
              id={filter._id}
              key={filter?._id}
              type={filter?.type}
              filters={filter?.filters}
              onChange={onFilterChange}
              isLast={isLast}
            />
          </>
        );
      })}
    </aside>
  );
}

export default Filters;
