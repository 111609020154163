import { default as dayjs } from 'dayjs';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';
import arrowUpRounded from '../../../assets/images/Software/arrowUpRounded.svg';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetSoftwareQuery } from '../../../services/api';
import { Device, Software } from '../../../types/Device';
import { AccordionPage } from './AccordionPage';

type ItemDetailsProps = {
  software?: Software | null;
  device?: Device | null;
};
const ItemDetails = ({ software, device }: ItemDetailsProps): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });
  const params = useParams<{ id: string } | undefined>();

  if (software === undefined) {
    const {
      data: softwaresData,
      isLoading,
      isSuccess,
      isError,
    } = useGetSoftwareQuery(
      {
        softwareId: params?.id.toString() ? params.id.toString() : '',
      },
      { refetchOnMountOrArgChange: true },
    );

    software = softwaresData;

    return (
      <div className="flex justify-center flex-col items-center w-full  mt-16 h-full">
        <div className="w-full mt-0 xl:mt-0 flex flex-col xl:flex-row px-6">
          <div className="flex flex-col w-full">
            <span className="text-3xl font-semibold ">{getValueFromLang(software?.name)}</span>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col w-full ">
                <span className="text-sm mt-4 mb-1">{i18n.t('product.latestBuild')}</span>
                <span className="text-sm"> {dayjs(software?.latestBuild).format('MMM D, YYYY h:mm A')}</span>
              </div>
              <div className="flex flex-col xl:flex-row justify-between  xl:justify-end items-start lg:items-end mt-4 xl:mt-0 w-full">
                <div className="flex items-center justify-end font-bold text-left">
                  <Link to="/how-to-use" className="text-sm text-linkColor hover:text-linkColorHover mr-8">
                    {i18n.t('product.howToUse')}
                  </Link>
                  <div className="text-sm text-linkColor hover:text-linkColorHover font-bold flex">
                    <Link
                      to="/products"
                      href="https://www.google.com"
                      className="text-sm text-linkColor hover:text-linkColorHover"
                    >
                      {i18n.t('product.devicePage')}
                    </Link>
                    <img className="ml-2" src={arrowUpRounded} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 mt-0 py-10 w-full">
          <AccordionPage software={software ? software : null} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center flex-col items-center w-full  mt-16 h-full">
        <div className="w-full mt-0 xl:mt-0 flex flex-col xl:flex-row px-6">
          <div className="flex flex-col w-full">
            <span className="text-3xl font-semibold ">{getValueFromLang(software?.name)}</span>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col w-full ">
                <span className="text-sm mt-4 mb-1">{i18n.t('software.latestBuild')}</span>
                <span className="text-sm">{dayjs(software?.latestBuild).format('MMM D, YYYY h:mm A')}</span>
              </div>
              <div className="flex flex-col xl:flex-row justify-between  xl:justify-end items-start lg:items-end mt-4 xl:mt-0 w-full">
                <div className="flex items-center justify-end font-bold text-left">
                  <Link to="/how-to-use" className="text-sm text-linkColor hover:text-linkColorHover mr-8">
                    {i18n.t('navigation.contact')}
                  </Link>
                  <div className="text-sm text-linkColor hover:text-linkColorHover font-bold flex">
                    <Link to={`product/`} className="text-sm text-linkColor hover:text-linkColorHover">
                      {i18n.t('product.devicePage')}
                    </Link>
                    <img className="ml-2" src={arrowUpRounded} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 mt-0 py-10 w-full">
          <AccordionPage software={software ? software : null} />
        </div>
      </div>
    );
  }
};

export default ItemDetails;
