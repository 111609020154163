import React from 'react';
import { useMediaQuery } from 'react-responsive';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import SwiperCore, { Autoplay, Navigation, Pagination, Thumbs } from 'swiper/core';
import 'swiper/swiper.min.css';
import { v4 } from 'uuid';
import mediaStar2 from '../../../assets/images/Air Conditioner/MS-HF24-Corex.png';
import mediaStar1 from '../../../assets/images/Products/mediaStar-1.svg';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { Product } from '../../../types/Product';
import Slider from './Slider';
SwiperCore.use([Navigation, Thumbs, Autoplay, Pagination]);

const images = [mediaStar1, mediaStar1, mediaStar1, mediaStar2, mediaStar2, mediaStar2];

type OverViewProps = {
  product: Product;
};
const OverView = ({ product }: OverViewProps): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  return (
    <div className="w-full xl:flex-row overflow-hidden grid xl:grid-cols-12 grid-cols-1 xl:gap-x-24 xl:mt-14 px-6 xl:p-0">
      <Slider images={product.images} className="xl:col-start-1 xl:col-end-9 mt-0 xl:mt-20" />

      <div className="flex flex-col text-sm xl:w-full xl:col-start-9 xl:col-end-13 mt-16 lg:mt-0">
        <h1 className="text-2xl font-bold w-full ">{getValueFromLang(product.name)}</h1>
        <p className="text-gray-400 text-sm font-bold mt-1">
          <span className="text-gray-800">{i18n.t('common.mediastar')}</span>
          {' ' + getValueFromLang(product?.category?.parent?.name)}
        </p>
        <ul className="mt-16 xl:w-4/5 xl:mb-32">{getValueFromLang(product.description)}</ul>
        {isLarge && (
          <div className="xl:bg-lightGray w-full flex flex-col justify-center px-5 py-10 rounded-xl items-center">
            <button className="text-white bg-red-700 rounded-3xl px-7 lg:px-12 py-3 self-start mx-auto ">
              {i18n.t('common.nowInMarkets')}
            </button>
            <div className="flex justify-center flex-col pt-10 ">
              <span className="text-center">{i18n.t('common.availableIn')}</span>
              <div className="flex justify-center mt-3">
                {product.availability.map((src) => {
                  return <img key={v4()} src={src} className="mx-2" height={24} width={24} />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverView;
