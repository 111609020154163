import { default as dayjs } from 'dayjs';
import { FilterCategory } from './components/pages/Product/Filters';
import i18n from './i18n';
import { lang as langFromStore } from './index';
import { Category } from './types/Product';
import { Language } from './types/Tutorial';

export function getValueFromLang(names: any): string {
  // If the "lang" key exists in the localStorage, use that.

  if (names?.length > 0) {
    if (langFromStore !== undefined) {
      if (names && typeof names[0] !== 'string') {
        return names.find((name: Language) => name.key === langFromStore).value;
      }
    }
    // If the "lang" key doesn't exist in the localStorage then make English the default
    else if (names && typeof names[0] !== 'string') {
      return names.find((name: Language) => name.key === 'en').value;
    }
  }

  if (i18n.language === 'en') {
    return 'Invalid data from API.';
  } else if (i18n.language === 'ku') return 'داتای هەڵە هەیە لە سێرڤەرەکەدا.';
  else {
    return 'بيانات غير صالحة من خادم.';
  }
}

export function getYoutubeId(url: string | string[]): string | undefined {
  if (!Array.isArray(url)) {
    url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
}

export function makeQueryString(queryVars: { [key: string]: string }): string {
  const queryStringParts = [];
  for (const key in queryVars) {
    queryStringParts.push(key + '=' + queryVars[key]);
  }
  return queryStringParts.join('&');
}

export function convertCategoriesToFilters(c: Category[]): FilterCategory[] {
  const filters: any = [];

  const categories = [...c];

  // filters.push({
  //   _id: '',
  //   filters: categories.map((category) => {
  //     return {
  //       _id: category._id,
  //       text: getValueFromLang(category.name),
  //       isParent: category.descendants ? category?.descendants?.length !== 0 : true,
  //       parentId: category.parent !== null ? category.parent?._id : null,
  //     };
  //   }),
  //   type: i18n.t('product.deviceTypes'),
  // });

  categories.forEach((category) => {
    const f = category.descendants?.map((descendant: Category) => {
      return {
        _id: descendant._id,
        text: getValueFromLang(descendant.name),
        isParent: descendant?.parent ? false : true,
        parentId: descendant?.parent,
      };
    });
    filters.push({
      _id: category._id,
      filters: f,
      type: getValueFromLang(category.name),
    });
  });

  return filters;
}

export function calculateIsNew(givenDate: Date): boolean {
  const currentDate = dayjs(new Date());
  const productDate = dayjs(new Date(givenDate));

  // If it's less than a month old
  return currentDate.diff(productDate, 'days') < 30;
}
