import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isRtl } from '../../..';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetSoftwaresQuery } from '../../../services/api';
import { Device, Software } from '../../../types/Device';
import Input from '../../UI/Input/Input';
import { Loader } from '../../UI/Loader';
import Item from './Item';
import ItemDetails from './ItemDetails';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '700px',
    minWidth: '800px',
    borderRadius: '2rem',
    border: 'none',
    scrollbarWidth: 'thin',
  },
  overlay: {
    background: 'rgba(0,0,0,0.3)',
  },
};

type ItemsSectionProps = {
  devices: Device[];
};
const ItemsSection = ({ devices }: ItemsSectionProps): JSX.Element => {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(devices[0]);
  const [selectedSoftware, setSelectedSoftware] = useState<Software | null>(null);
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: softwaresData,
    isLoading,
    isSuccess,
    isError,
  } = useGetSoftwaresQuery(
    {
      deviceId: query.deviceId?.toString(),
      search: query.search?.toString(),
    },
    { refetchOnMountOrArgChange: true },
  );
  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSoftwareClick = (software: Software) => {
    setSelectedSoftware(software);
    openModal();
  };

  const handleDeviceClick = (id: string) => {
    query.deviceId = id;

    history.push({
      pathname: '/software',
      search: queryString.stringify(query),
    });
  };

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    query.search = value;

    history.push({
      pathname: '/software',
      search: queryString.stringify(query),
    });
  };

  useEffect(() => {
    setSearchQuery(query?.search?.toString() !== undefined ? query.search?.toString() : '');
  }, [query.search?.toString()]);
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Devices Updates"
        style={customStyles as any}
      >
        <button className="absolute top-8" onClick={closeModal} style={isRtl ? { left: '2rem' } : { right: '2rem' }}>
          <CloseIcon width={16} height={16} />
        </button>
        {selectedSoftware ? (
          <ItemDetails software={selectedSoftware} device={selectedDevice} />
        ) : (
          'No software has been selected.'
        )}
      </Modal>
      <div className="bg-lightGray w-full flex flex-col items-center xl:items-start mb-3 pb-40 xl:pb-96 xl:bg-white">
        {isLarge ? (
          <div className="xl:grid xl:grid-cols-6 gap-x-6 w-full">
            <div className="hidden col-start-1 col-end-3 xl:flex xl:flex-col">
              <span className="font-bold text-lg mb-4">{i18n.t('common.devices')}</span>
              <div className="w-full text-left h-full  xl:bg-lightGray rounded-3xl p-5">
                <ul className="ml-5 mt-4 ">
                  <li
                    onClick={() => handleDeviceClick('')}
                    key={''}
                    className={`my-3 p-4 cursor-pointer ${
                      query.deviceId?.toString() === '' && 'bg-red-700 rounded-xl text-white'
                    }`}
                  >
                    {i18n.t('software.allDevices')}
                  </li>
                  {devices.map((device) => (
                    <li
                      onClick={() => handleDeviceClick(device._id)}
                      key={device._id}
                      className={`my-3 p-4 cursor-pointer ${
                        query.deviceId?.toString() === device._id && 'bg-red-700 rounded-xl text-white'
                      }`}
                    >
                      {getValueFromLang(device?.name)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex flex-col xl:ml-4 w-full xl:col-start-3 xl:col-end-7">
              <div className="flex flex-row justify-between w-full items-center">
                <span className="font-bold text-lg mb-4">{getValueFromLang(selectedDevice?.name)}</span>

                <div className=" relative hidden xl:flex w-1/2">
                  <SearchIcon className="absolute right-5 top-3" />
                  <Input
                    placeholder={i18n.t('product.searchInput.placeholder')}
                    value={searchQuery}
                    onChange={(event) => handleSearchQuery(event.target.value)}
                    className="!rounded-3xl mb-5 md:w-full p-3 text-sm px-4 "
                    name="search"
                  />
                </div>
              </div>
              <div
                className={`w-full grid grid-cols-2 gap-6 ${
                  (isLoading || softwaresData?.result?.length === 0) &&
                  '!flex !flex-col !justify-center !items-center !h-full'
                }`}
              >
                {isLoading && <Loader isLoading={true} />}
                {softwaresData !== undefined && softwaresData?.result?.length > 0 ? (
                  softwaresData?.result.map((software) => {
                    return (
                      <>
                        <Item
                          name={getValueFromLang(software?.name)}
                          key={software._id}
                          onClick={() => handleSoftwareClick(software)}
                          image={software.image}
                          version={software.versions[0].fileName}
                          latestBuild={software.latestBuild.toString()}
                        />
                      </>
                    );
                  })
                ) : (
                  <h2 className="text-main text-lg font-semibold"> {i18n.t('software.noSoftwareFound')}</h2>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center flex-col col-start-3 col-end-6">
            {softwaresData?.result.map((software) => {
              return (
                <Link to={{ pathname: `/software/device/${software?._id}` }} key={software?._id}>
                  <Item
                    name={getValueFromLang(software?.name)}
                    key={software._id}
                    onClick={() => handleSoftwareClick(software)}
                    image={software.image}
                    version={software.versions[0].number}
                    latestBuild={software.latestBuild.toString()}
                  />
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ItemsSection;
