import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { isRtl } from '../../..';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { ReactComponent as Logo } from '../../../assets/icons/logo-full-dark-white.svg';
import { queries } from '../../../devices';
import i18n from '../../../i18n';
import { Feature } from '../../../types/Product';
type ProductProps = {
  image: any;
  name: string;
  id: string;
  nameClassName: string;
  features: Feature[];
  className?: string;
  description: string;
  isEnd: boolean;
};

function Product({
  image,
  name,
  id,
  description,
  nameClassName,
  features,
  className,
  isEnd,
}: ProductProps): JSX.Element {
  const isLarge = useMediaQuery({
    query: queries.xl,
  });
  const between = useMediaQuery({
    query: '(max-width:1535px) and (min-width:1280px)',
  });
  return (
    <div className={`flex flex-col ${className} xl:relative `} style={{ minHeight: isLarge ? '540px' : '' }}>
      <div
        className={`flex flex-col items-center xl:items-start ${isEnd && isLarge && 'ml-32'} ${
          isEnd && between && '!ml-4'
        }`}
      >
        <div className="flex flex-row items-center">
          <Logo className="w-20 xl:w-24 fill-current text-white" />
        </div>
        <div className="flex justify-between items-center mt-2 xl:mt-4 xl:mt-1 xl:mb-4 ">
          <h2 className={`font-semibold text-4xl ${nameClassName}`}>{name}</h2>
          <div className="hidden xl:flex ml-4 -mt-1">
            {features?.map((feature: Feature) => {
              return (
                <div
                  key={feature._id}
                  className="border-2 font-semibold text-white px-2 py-[2px] rounded-md text-xs ml-2"
                >
                  {feature.text}
                </div>
              );
            })}
          </div>
        </div>
        <p
          className={`text-lightGray text-opacity-60 hidden xl:block xl:w-2/5 ${
            between && '!w-3/5'
          } leading-relaxed text-sm`}
          style={{ width: isEnd ? '48%' : '' }}
        >
          {description}
        </p>
        <Link to={`/product/${id}`} className="mt-2 xl:mt-6 flex items-center text-sm xl:text-base">
          <span className="mr-2 text-brand hover:xl:hover:text-red-700 text-sm transition transition">
            {i18n.t('common.learnMore')}
          </span>{' '}
          <Caret
            className="stroke-current text-brand xl:text-brand hover:xl:hover:text-red-700 icon transition transition"
            width={10}
            height={10}
          />
        </Link>
      </div>
      <div className={`xl:absolute mt-8 ${isEnd ? '-right-2/5' : '-right-1/5'} w-full h-full`} style={{ top: '32%' }}>
        <div className="relative">
          <div className={`${isRtl ? 'product-overlay-rtl' : 'product-overlay'} absolute right-0`}></div>
          <img src={image} height="auto" className="w-4/5 mx-auto xl:ml-auto" />
        </div>
      </div>
    </div>
  );
}

export default Product;
