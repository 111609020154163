import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';
import feature1 from '../../../assets/images/Products/feature-1.svg';
import feature2 from '../../../assets/images/Products/feature-2.svg';
import feature3 from '../../../assets/images/Products/feature-3.svg';
import feature4 from '../../../assets/images/Products/feature-4.svg';
import { queries } from '../../../devices';
import { calculateIsNew, getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetSimilarProductsQuery } from '../../../services/api';
import { Loader } from '../../UI/Loader';
import RelatedProduct from './RelatedProduct';

const relatedProducts = [
  {
    title: 'A small box for best experience',
    img: feature1,
  },
  {
    title: 'Designed with your home in mind.',
    img: feature2,
  },
  {
    title: 'Media player support',
    img: feature3,
    condition: 'new',
  },
  {
    title: '4k Ultra HD Quality',
    img: feature4,
    condition: 'new',
  },
];

const RelatedProductsSection = (): JSX.Element => {
  const params = useParams<{ id: string | undefined }>();
  const isLarge = useMediaQuery({
    query: queries.xl,
  });
  const { isLoading, data: products } = useGetSimilarProductsQuery(params?.id !== undefined ? params?.id : '');
  return (
    <Fragment>
      <div className="flex justify-center flex-col text-center w-10/12 pt-10 ">
        <div className="w-full flex flex-col justify-center pb-4 text-center xl:text-left border-b border-gray-200 ">
          <span className="text-[24px] pb-2 xl:text-base ">{i18n.t('itemDetails.relatedProducts')}</span>
          <Link to="/product" className="text-linkColor hover:text-linkColorHover font-bold xl:hidden">
            {i18n.t('home.devicesSection.explore')}
          </Link>
        </div>
        <div className="pt-20 flex flex-col justify-center items-center xl:grid lg:grid-cols-1 lg:gap-x-7 lg:grid-cols-4 ">
          {isLoading ? (
            <Loader isLoading={true} />
          ) : (
            products?.slice(0, isLarge ? products.length : 2).map((product) => {
              const isNew = calculateIsNew(product?.createdAt);
              return (
                <RelatedProduct
                  id={product?._id}
                  key={product?._id}
                  isNew={isNew}
                  image={product.images[0]}
                  title={getValueFromLang(product?.name)}
                />
              );
            })
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default RelatedProductsSection;
