import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { ReactComponent as LogoDark } from '../../../assets/icons/logo-full-dark.svg';
import { ReactComponent as LogoLight } from '../../../assets/icons/logo-full-light.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg';
import i18n from '../../../i18n';
import Dropdown from '../Dropdown';
import { languageItems } from './Navigation';

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-100%', display: 'none' },
};

type Props = {
  isCurrentRouteHome: boolean;
};
function MobileNavigation({ isCurrentRouteHome }: Props): JSX.Element {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const ref = useRef(null);

  useClickAway(ref, () => {
    closeNavigation();
  });

  const closeNavigation = () => {
    setIsNavigationOpen(false);
  };

  const toggleNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };
  return (
    <>
      <nav
        className={`flex fixed top-0 left-0 mt-0 m-0 py-6 px-8 shadow-sm flex-col w-full justify-center z-30 ${
          isCurrentRouteHome ? 'bg-black  ' : 'bg-white'
        }`}
        ref={ref}
      >
        <div className="flex flex-row justify-between w-full">
          <Link to="/">
            {isCurrentRouteHome ? <LogoDark className="flex -ml-1" /> : <LogoLight className="flex -ml-1" />}
          </Link>

          <button className="outline-none p-0" onClick={toggleNavigation}>
            <MenuIcon fill={isCurrentRouteHome ? 'white' : ''} />
          </button>
        </div>

        <motion.div animate={isNavigationOpen ? 'open' : 'closed'} variants={variants} className="h-screen">
          <ul className="list-none mt-8 z-10">
            <li
              className={`navigation-item ${isCurrentRouteHome ? 'text-gray-50' : 'text-main'}`}
              onClick={closeNavigation}
            >
              <Link to="/product">{i18n.t('navigation.product')}</Link>
            </li>
            <li
              className={`navigation-item ${isCurrentRouteHome ? 'text-gray-50' : 'text-main'}`}
              onClick={closeNavigation}
            >
              <Link to="/software">{i18n.t('navigation.software')}</Link>
            </li>
            <li
              className={`navigation-item ${isCurrentRouteHome ? 'text-gray-50' : 'text-main'}`}
              onClick={closeNavigation}
            >
              <Link to="/how-to-use">{i18n.t('navigation.howToUse')}</Link>
            </li>
            <li
              className={`navigation-item ${isCurrentRouteHome ? 'text-gray-50' : 'text-main'}`}
              onClick={closeNavigation}
            >
              <Link to="/about-us">{i18n.t('navigation.aboutUs')}</Link>
            </li>
            <li
              className={`navigation-item ${isCurrentRouteHome ? 'text-gray-50' : 'text-main'}`}
              onClick={closeNavigation}
            >
              <Link to="/contact">{i18n.t('navigation.contact')}</Link>
            </li>
          </ul>

          <div className="flex flex-col mt-8">
            <div>
              <p className={`text-sm font-light ${isCurrentRouteHome ? 'text-gray-200' : 'text-gray-300'}`}>
                stay in touch
              </p>
              <hr className="mt-2" />
            </div>
            <div className={`grid grid-cols-2 mt-6 gap-y-2 ${isCurrentRouteHome ? 'text-gray-200' : 'text-gray-500'}`}>
              <a href="https://www.facebook.com/Mediastarofficial/">{i18n.t('navigation.facebook')}</a>
              <a href="#">{i18n.t('navigation.youtube')}</a>
              <a href="https://www.instagram.com/mediastariraq/">{i18n.t('navigation.instagram')}</a>
              <a href="#">{i18n.t('navigation.twitter')}</a>
            </div>
          </div>

          <div className="mt-16">
            <Dropdown
              selectedItemKey={i18n.language}
              getSelectedKey={(language) => {
                window.location.reload();
                i18n.changeLanguage(language);
              }}
              hasIcon={true}
              items={languageItems}
              dropdownButtonClassName={`w-full ${
                isCurrentRouteHome ? 'bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover' : 'bg-white'
              }`}
              dropdownClassName="w-full"
              dropdownItemClassName={`${
                isCurrentRouteHome ? 'bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover' : 'bg-white'
              }`}
              dropdownItemsClassName={`${
                isCurrentRouteHome ? 'bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover' : 'bg-white'
              }`}
            />
          </div>
        </motion.div>
      </nav>
      <div className="bg-black w-full py-6 px-8 ">&nbsp;</div>
    </>
  );
}

export default MobileNavigation;
