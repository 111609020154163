import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { ReactComponent as DropdownIcon } from '../../assets/icons/dropdown-icon.svg';

type DropdownItemProps = {
  text: string;
  icon: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className: string | undefined;
};

function DropdownItem<FC>({ icon, text, onClick, className }: DropdownItemProps) {
  return (
    <button
      className={`border-none outline-none w-full bg-white cursor-pointer px-8 py-3 flex text-main no-underline align-middle items-center rounded-lg hover:bg-gray-100 justify-between ${className} transition`}
      onClick={onClick}
    >
      <span className={`ml-0 ${className} bg-opacity-0`}>{text}</span>
    </button>
  );
}

export type DropdownItemType = {
  key: string;
  text: string;
  icon?: any;
};

type DropdownProps = {
  items: DropdownItemType[];
  hasIcon: boolean;
  style?: React.CSSProperties;
  dropdownButtonClassName?: string | undefined;
  dropdownClassName?: string | undefined;
  dropdownItemsClassName?: string | undefined;
  dropdownItemClassName?: string | undefined;
  getSelectedKey: (key: string) => void;
  selectedItemKey?: string | undefined;
};

function Dropdown({
  getSelectedKey,
  items,
  dropdownButtonClassName,
  dropdownClassName,
  dropdownItemsClassName,
  dropdownItemClassName,
  hasIcon,
  selectedItemKey,
}: DropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(selectedItemKey !== undefined ? selectedItemKey : items[0].key);
  const ref = useRef(null);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // Not the first update
    getSelectedKey(selectedKey);
  }, [selectedKey]);

  const selectedItem: any | undefined = items.find((item) => item.key === selectedKey);

  useClickAway(ref, () => {
    setIsOpen(!isOpen);
  });

  const handleDropdownClick = (key: string) => {
    setSelectedKey(key);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${dropdownClassName}`}>
      <button
        className={`flex  text-center items-center outline-none bg-lightGray font-light cursor-pointer px-8 text-center rounded-3xl h-12  justify-between ${dropdownButtonClassName} w-full `}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedItem !== undefined && (
          <div>
            <span className="mr-4">{selectedItem?.text}</span>
          </div>
        )}
        <DropdownIcon height={8} />
      </button>

      {isOpen && (
        <div
          className={`flex flex-col z-10 shadow-dropdown border-gray-100  border-solid border  mt-5 absolute justify-center top-8 rounded-xl w-full ${dropdownItemsClassName}`}
          ref={ref}
        >
          {items?.map((item) => {
            return (
              <DropdownItem
                className={dropdownItemClassName}
                key={item?.key}
                icon={<span></span>}
                onClick={() => handleDropdownClick(item?.key)}
                text={item?.text}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
