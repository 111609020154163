import React, { Fragment } from 'react';
import earth from '../../../assets/images/Products/earth.svg';
import handPointing from '../../../assets/images/Products/hand-pointing.svg';
import i18n from '../../../i18n';

const GetInTouchSection = (): JSX.Element => {
  return (
    <Fragment>
      <div className="felx justify-center text-center flex-col py-32 w-full">
        <div className="w-full flex justify-center">
          <img src={earth} alt="earth" />
        </div>
        <div className="py-10 px-11 ">
          <span className="">{i18n.t('getInTouchSection.getInTouch')}</span>
          <p className="text-lg font-semibold text-center mt-4  sm:w-9/12 md:w-7/12 xl:w-3/12 mx-auto">
            {i18n.t('getInTouchSection.alwaysAvailable')}
          </p>
        </div>
        <div>
          <button className="text-white bg-brand hover:bg-red-700 rounded-3xl px-7 py-3">
            {i18n.t('getInTouchSection.sayHello')}
          </button>
        </div>
        <div className="flex justify-center pt-10 pb-20">
          <img src={handPointing} alt={'hello'} />
        </div>
      </div>
    </Fragment>
  );
};

export default GetInTouchSection;
