import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import feature1 from '../../../assets/images/Products/feature-1.svg';
import { queries } from '../../../devices';

type TProps = {
  title: string;
  img: string;
  description?: string;
  isOdd: boolean;
};

const Features = ({ title, img, description, isOdd }: TProps): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });
  return (
    <Fragment>
      {isLarge ? (
        <div className="flex  justify-around text-center py-44 ">
          {!isOdd && (
            <div className="w-6/12">
              <div className="w-full text-center flex justify-center">
                <img className="w-8/12" src={img} alt="feature" />
              </div>
            </div>
          )}
          <div className="w-6/12 flex justify-center">
            <div className={`w-8/12 text-left`}>
              <h3 className="text-3xl font-bold">{title}</h3>
              <p className="mt-20 xl:mt-8">{description}</p>
            </div>
          </div>
          {isOdd && (
            <div className="w-6/12">
              <div className="w-8/12 text-center flex justify-center">
                <img className="w-full" src={img} alt="feature" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center text-center py-32">
          <h3 className="text-3xl font-bold">{title}</h3>
          <p className="mt-20 xl:mt-8">{description}</p>
          <div className="w-full text-center flex justify-center">
            <img className="w-10/12" src={img} alt="feature" />
          </div>
        </div>
      )}
    </Fragment>
  );
};
Features.defaultProps = {
  title: 'Feature Title',
  description: '',
  img: feature1,
};

export default Features;
