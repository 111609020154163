import { InputHTMLAttributes } from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = ({ placeholder, value, onChange, name, className }: InputProps) => {
  return (
    <input
      className={`bg-white border-solid  w-full  border-gray-300  placeholder-gray-300::placeholder text-xs text-start focus:outline-none  ${className}`}
      style={{ borderWidth: '1px' }}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
    />
  );
};

export default Input;
