import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { isRtl } from '../../..';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import LineupImages from '../../../assets/images/lineup-images.png';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetProductsLineupQuery } from '../../../services/api';

function DevicesLineupSection(): JSX.Element {
  const isLarge = useMediaQuery({
    query: queries.xl,
  });
  const { data: productsLineup, error, isLoading } = useGetProductsLineupQuery('');

  console.log('Lineup', productsLineup);
  const between = useMediaQuery({
    query: '(max-width:1535px) and (min-width:1280px)',
  });

  return (
    <div className="flex flex-col xl:col justify-center px-6 xl:px-2 bg-white  w-full ">
      <div className="container mt-32">
        <div className="flex flex-col items-center xl:items-center xl:justify-center xl:flex-1 mt-12">
          <h2 className="text-main text-3xl font-semibold text-center xl:text-5xl  xl:w-11/12 ">
            {i18n.t('home.lineupSection.title')}
          </h2>
          <Link to="/product" className="text-brand hover:text-red-700 my-6 flex items-center transition">
            <span className="mr-2 ">{i18n.t('common.exploreAllProducts')}</span>{' '}
            <Caret className="stroke-current icon" width={10} height={10} />
          </Link>
        </div>

        {productsLineup !== undefined && productsLineup.length > 0 ? (
          <div className="xl:grid grid-cols-1 xl:grid-cols-6 xl:grid-rows-devicesLineup w-full gap-x-4 gap-y-4 mt-10 hidden">
            <div
              className="bg-red rounded-3xl  bg-[#F5F5F5] w-full flex py-20 flex-col xl:row-start-1 xl:col-start-1 xl:row-end-2 xl:col-end-4 xl:bg-cover bg-no-repeat bg-center xl:items-center"
              style={{
                backgroundImage: `url(${productsLineup[0].images[0]})`,
                backgroundPositionY: '130px',
                backgroundSize: '490px',
              }}
            >
              <p className="text-gray-400 font-semibold text-base self-center ">
                <span className=" text-main">{i18n.t('common.mediaStar')}</span>{' '}
                {getValueFromLang(productsLineup[0].category?.parent?.name)}
              </p>
              <h3
                className={`text-xl font-semibold  text-main text-center md:w-1/2 xl:text-3xl xl:px-20  mt-4 xl:w-3/5 self-center ${
                  between && '!w-4/5'
                }`}
              >
                {getValueFromLang(productsLineup[0].title)}
              </h3>
            </div>

            <div
              className="rounded-3xl  bg-[#F5F5F5] w-full flex py-20 flex-col xl:bg-cover bg-no-repeat bg-center relative xl:row-start-1 xl:col-start-4 xl:row-end-2 xl:col-end-7"
              style={{
                backgroundImage: `url(${productsLineup[1].images[0]})`,
                backgroundPositionY: 'center',
                backgroundPositionX: between ? (isRtl ? '-150px' : '300px') : isRtl ? '-80px' : '300px',
                backgroundSize: '510px',
              }}
            >
              <p className="text-gray-400 font-semibold text-base px-12 ">
                <span className=" text-main">{i18n.t('common.mediaStar')}</span>{' '}
                {getValueFromLang(productsLineup[1].category?.parent?.name)}
              </p>
              <h3
                className={`text-xl font-semibold  text-main text-left md:w-1/2 xl:text-3xl md:px-10 xl:px-12 xl:pr-28  mt-4 pr-12 ${
                  between && '!pr-12'
                }`}
              >
                {getValueFromLang(productsLineup[1].title).substring(0, 50)}
              </h3>
            </div>

            <div
              className="bg-red rounded-3xl  bg-[#F5F5F5] w-full flex py-20 flex-col xl:row-start-2 xl:col-start-3 xl:row-end-3 xl:col-end-7 "
              style={{
                backgroundImage: `url(${productsLineup[2].images[0]})`,

                backgroundPositionY: 'center',
                backgroundPositionX: between ? (isRtl ? '-250px' : '400px') : isRtl ? '-150px' : '480px',

                backgroundSize: '700px',

                backgroundRepeat: 'no-repeat',
              }}
            >
              <p className={`text-gray-400 font-semibold text-base px-20`}>
                <span className=" text-main">{i18n.t('common.mediaStar')}</span>{' '}
                {getValueFromLang(productsLineup[2].category?.parent?.name)}
              </p>
              <h3
                className={`text-xl font-semibold  text-main text-left md:w-1/2 xl:text-3xl md:px-10 xl:px-20  mt-4 ${
                  between && '!pr-12'
                }`}
              >
                {getValueFromLang(productsLineup[2].title).substring(0, 50)}
              </h3>
            </div>

            <div
              className="bg-[#F5F5F5] py-12 flex flex-col items-start xl:justify-center px-12 rounded-3xl  xl:row-start-2 xl:col-start-1 xl:row-end-3 xl:col-end-3"
              style={{
                backgroundImage: `url(${productsLineup[3]?.images[0]})`,
                backgroundPositionY: '20px',
                backgroundPositionX: between ? (isRtl ? '-250px' : '0px') : isRtl ? '-230px' : '0px',
                backgroundSize: between ? (isRtl ? '650px' : '650px') : '700px',

                backgroundRepeat: 'no-repeat',
              }}
            >
              <p className="text-gray-400 font-semibold text-base  ">
                <span className=" text-main">{i18n.t('common.mediaStar')}</span>{' '}
                {getValueFromLang(productsLineup[3]?.category?.parent?.name)}
              </p>
              <h3 className="text-xl font-semibold  text-main text-left md:w-1/2 xl:text-3xl xl:px-0  mt-4 ">
                {getValueFromLang(productsLineup[3]?.title).substring(0, 50)}
              </h3>
            </div>
          </div>
        ) : (
          <div className="mt-10 w-full text-white">{i18n.t('common.noProductsFound')}.</div>
        )}

        {productsLineup !== undefined && productsLineup.length > 0 ? (
          <div className="xl:hidden relative ">
            {/* <img
              src={productsLineup[0].images[0]}
              className="absolute "
              style={{ zIndex: 1, right: '-180px', top: '90px' }}
            />
            <img src={productsLineup[1].images[0]} className="absolute " style={{ zIndex: 3, bottom: 0, left: 0 }} />
            <img
              src={productsLineup[2].images[0]}
              className="absolute bottom-0 left-0"
              style={{ zIndex: 2, left: '20%', bottom: '20%' }}
            /> */}

            <img src={LineupImages} className="mx-auto mt-8" />
          </div>
        ) : (
          <div>{i18n.t('common.noProductsFound')}.</div>
        )}
      </div>
    </div>
  );
}

export default DevicesLineupSection;
