import React, { FunctionComponent } from 'react';

type LoaderProps = {
  isLoading: boolean;
  stroke?: string;
  className?: string;
};

export const Loader: FunctionComponent<LoaderProps> = (props) => {
  if (!props.isLoading) {
    return null;
  }

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ background: '0 0' }}
      width="32"
      height="32"
      className={`loader ${props.className}`}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={props.stroke ? props.stroke : '#DA291C'}
        strokeWidth="8"
        r="35"
        strokeOpacity="0.85"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(149.951 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="0.5"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
