import queryString from 'query-string';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ProductIllustration } from '../../../assets/icons/illustrations/product-illustration.svg';
import { ReactComponent as Outlink } from '../../../assets/icons/outlink-no-circle.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { queries } from '../../../devices';
import { calculateIsNew, getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetCategoriesQuery, useGetProductsQuery } from '../../../services/api';
import Button from '../../UI/Button/Button';
import Dropdown from '../../UI/Dropdown';
import Input from '../../UI/Input/Input';
import { Loader } from '../../UI/Loader';
import { FilterType } from './Filter';
import Filters from './Filters';
import ProductCard from './ProductCard';

//
function Product(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const query = queryString.parse(location.search);

  const isLarge = useMediaQuery({
    query: queries.xl,
  });

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [isSortVisible, setIsSortVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const between = useMediaQuery({
    query: '(max-width:1535px) and (min-width:1280px)',
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    setSearchQuery(query?.search?.toString() !== undefined ? query.search?.toString() : '');
  }, [query.search?.toString()]);
  const {
    data: productsData,
    isLoading: productsIsLoading,
    isFetching: productsIsFetching,
    isError: productsIsError,
  } = useGetProductsQuery(
    {
      search: query.search?.toString(),
      page: query.page?.toString(),
      limit: query.limit?.toString(),
      orderBy: query.orderBy?.toString(),
      sortBy: query.sortBy?.toString(),
      parentId: query.parentId?.toString().trim(),
      categoryId: query.categoryId?.toString().trim(),
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isSuccess: categoriesIsSuccess,
    isError: categoriesIsError,
  } = useGetCategoriesQuery('');

  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  useEffect(() => {
    toggleSort('desc');

    if (query.page?.toString() === undefined) {
      query.page = '1';
      history.push({
        pathname: '/product',
        search: queryString.stringify(query),
      });
    }
  }, []);
  const toggleSort = (key: string) => {
    query.sortBy = 'createdAt';
    query.orderBy = key;
    history.push({
      pathname: '/product',
      search: queryString.stringify(query),
    });
  };

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    query.search = value;

    history.push({
      pathname: '/product',
      search: queryString.stringify(query),
    });
  };

  const handleFilterChange = (filter: FilterType) => {
    if (filter.isParent) {
      query.parentId = filter._id;
      query.categoryId = null;
      query.page = '1';

      history.push({
        pathname: '/product',
        search: queryString.stringify(query),
      });
    } else {
      query.categoryId = filter._id;
      query.parentId = null;
      query.page = '1';

      history.push({
        pathname: '/product',
        search: queryString.stringify(query),
      });
    }
  };

  const handleReset = () => {
    query.search = null;
    query.page = null;
    query.limit = null;
    query.orderBy = null;
    query.sortBy = null;
    query.parentId = null;
    query.categoryId = null;

    history.push({
      pathname: '/product',
      search: queryString.stringify(query),
    });
  };
  // Get all the categories
  // You'll have descendants
  // Render the categories and descendants
  // Make the first element of the array the main categories
  // The rest should be main category -> descendants

  const handlePageClick = (e: any) => {
    const pNumber = parseInt(query.page?.toString() !== undefined ? query.page?.toString() : '1');

    if (e.selected < page) {
      query.page = (pNumber - 1).toString();
      history.push({
        pathname: '/product',
        search: queryString.stringify(query),
      });
    } else {
      query.page = (pNumber + 1).toString();
      history.push({
        pathname: '/product',
        search: queryString.stringify(query),
      });
    }
  };
  return (
    <div className="flex flex-col  justify-center items-center px-4 xl:px-2 h-full  xl:mt-0 bg-[#F7F7F7] w-full">
      <div className="container mt-16 xl:mt-28 ">
        <section className="flex flex-col justify-between items-center xl:flex-row w-full ">
          <ProductIllustration className="xl:order-2 xl:h-[310px] xl:flex-1" />
          <div className="flex flex-col xl:flex-1">
            <h1 className="text-2xl font-semibold mt-16 text-main text-center xl:text-left xl:text-4xl  xl:w-[350px]">
              {i18n.t('product.title')}
            </h1>
            <p className="text-main mt-6 text-left  w-[500px] hidden xl:block">{i18n.t('product.subtitle')}</p>
            <div className="text-main mt-10 hidden xl:block">
              {i18n.t('product.haveAQuestion')}

              <Link
                to="/contact"
                className="hover:text-linkColorHover text-linkColor ml-1 items-center  xl:inline-flex transition "
              >
                <span className="mr-3 text-linkColor hover:text-linkColorHover transition">
                  {i18n.t('common.contactUs')}
                </span>
                <Outlink
                  className="-mt-1 inline text-linkColor hover:text-linkColorHover stroke-current icon transition"
                  width={12}
                  height={12}
                />
              </Link>
            </div>{' '}
          </div>
        </section>

        <section className="mt-24 w-full flex flex-col xl:flex-row">
          <div className="flex flex-col xl:flex-row justify-between w-full xl:items-center">
            <div className="flex-row hidden xl:flex">
              <p className="font-medium ">
                {productsData?.result?.length}{' '}
                {i18n.t('product.productsAvailable', { products: productsData?.result?.length })}
              </p>
              <button
                className={`border-0 bg-transparent text-brand text-base underline hover:text-red-800 ml-16 ${
                  between ? 'xl:ml-16' : 'xl:ml-32'
                } transition`}
                onClick={handleReset}
              >
                {i18n.t('product.resetAll')}
              </button>
            </div>
            <div className="flex mt-4 flex-col xl:flex-row items-center ">
              <div className="w-full xl:w-full relative">
                <Input
                  className="rounded-full placeholder-current text-gray-600  xl:text-sm xl:px-4 pl-4 py-2 xl:min-w-[350px] pr-10 text-sm"
                  placeholder={i18n.t('product.searchInput.placeholder')}
                  value={searchQuery}
                  onChange={(event) => handleSearchQuery(event.target.value)}
                />
                <SearchIcon className="absolute right-4 top-2 xl:top-3" />
              </div>

              <div className="flex flex-row xl:flex-col w-full mt-4 xl:mt-0 items-center justify-center">
                <Button
                  className="xl:hidden !border !border-solid !border-[#E5E5E5] !bg-white !text-main"
                  onClick={toggleFilters}
                  type="button"
                >
                  {i18n.t('product.filters')}
                </Button>
                <div>
                  <Dropdown
                    items={[
                      { key: 'desc', text: i18n.t('product.sort.newest') },
                      { key: 'asc', text: i18n.t('product.sort.oldest') },
                    ]}
                    getSelectedKey={(key) => toggleSort(key)}
                    hasIcon={false}
                    dropdownButtonClassName="ml-2 !border !border-solid !border-[#E5E5E5] !bg-white !text-main !h-auto py-2"
                  />
                </div>
              </div>
            </div>
            {!isLarge && isFiltersVisible && categories !== undefined && (
              <div className={`${!productsIsLoading && productsIsFetching && 'opacity-50 pointer-events-none'}`}>
                <Filters categories={categories} onFilterChange={handleFilterChange} />
                <p className="font-medium mt-6 self-start block xl:hidden ">
                  {i18n.t('product.productsAvailableShort', { products: productsData?.result?.length })}x
                </p>
              </div>
            )}
          </div>
        </section>
        <div className="grid grid-cols-8 gap-x-4">
          {isLarge && categories !== undefined && (
            <div
              className={`col-start-1 col-end-3 ${
                !productsIsLoading && productsIsFetching && 'opacity-50 pointer-events-none'
              }`}
            >
              <Filters className="col-start-1 col-end-3" categories={categories} onFilterChange={handleFilterChange} />
            </div>
          )}

          <section
            className={`grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 w-full mt-6 col-start-1 xl:col-start-3  col-end-9 auto-rows-max ${
              (productsIsLoading || productsIsError || categoriesIsLoading || productsData?.result.length === 0) &&
              '!flex !flex-col !justify-center !items-center'
            }`}
          >
            {productsIsLoading && <Loader isLoading={true} />}
            {productsData?.result.length === 0 && (
              <h2 className="font-semibold text-base lg:text-xl text-main">
                {i18n.t('product.noProductsFoundWithDescription')}
              </h2>
            )}

            {productsIsError && (
              <h2 className="font-semibold text-base lg:text-xl">{i18n.t('product.errorLoadingProducts')}</h2>
            )}

            {productsData?.result.map((product) => {
              const isNew = calculateIsNew(product?.createdAt);

              return (
                <ProductCard
                  categoryId={product.category}
                  id={product._id}
                  isNew={isNew}
                  name={getValueFromLang(product?.name)}
                  thumbnail={product.images[0]}
                  key={product._id}
                />
              );
            })}
          </section>

          <div className="col-start-1 xl:col-start-3  col-end-9 auto-rows-max flex justify-center items-center my-8">
            {productsData !== undefined && productsData?.result?.length > 0 && (
              <ReactPaginate
                previousLabel={i18n.t('common.previous')}
                nextLabel={i18n.t('common.next')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={productsData?.count / 4}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
