import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { isRtl } from '../../..';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { ReactComponent as SoftwareIcon } from '../../../assets/icons/software-download.svg';
import FluidBackground from '../../../assets/images/fluid-background.png';
import LearnMoreAbstraction from '../../../assets/images/learn-more-abstraction.png';
import Movies from '../../../assets/images/tv-shows.png';
import { queries } from '../../../devices';
import i18n from '../../../i18n';

function ExperiencesSection(): JSX.Element {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  const between = useMediaQuery({
    query: '(max-width:1535px) and (min-width:1280px)',
  });
  return (
    <div className="flex flex-col xl:col justify-center px-6 xl:px-2 bg-white  w-full ">
      <div className="container mt-10 xl:mt-32">
        <div className="flex flex-col items-center xl:items-center xl:justify-center xl:flex-1 mt-12">
          <h2 className="text-main text-3xl font-semibold text-center xl:text-5xl  xl:w-2/5 ">
            {isLarge ? i18n.t('home.experiences.titleLarge') : i18n.t('home.experiences.titleSmall')}
          </h2>
          <p className="text-black text-opacity-70 xl:text-lg   xl:text-center mt-6 font-light xl:px-2 text-center xl:w-2/5  ">
            {isLarge ? i18n.t('home.experiences.subtitleLarge') : i18n.t('home.experiences.subtitleSmall')}
          </p>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-6 xl:grid-rows-devices w-full gap-x-4 gap-y-4 mt-10 xl:mt-24">
          <div
            className="bg-red rounded-3xl px-8 py-10 bg-gray-300 w-full flex flex-col justify-center items-center xl:row-start-1 xl:col-start-1 xl:row-end-2 xl:col-end-4 xl:bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${FluidBackground})`,
            }}
          >
            <h3 className="text-xl font-semibold  text-white  md:w-1/2 xl:text-3xl xl:px-20  xl:w-9/12 text-center ">
              {i18n.t('home.experiences.first')}
            </h3>
          </div>

          <div
            className="bg-red rounded-3xl px-8 py-10 bg-cardGray w-full flex flex-col justify-center align-center relative xl:row-start-1 xl:col-start-4 xl:row-end-2 xl:col-end-7"
            style={{
              backgroundImage: `url(${Movies})`,
              backgroundPosition: isRtl ? '-10%' : '120%',
              backgroundSize: isLarge ? '60%' : '57%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <h3 className="text-xl font-semibold  text-main text-left z-10  xl:text-3xl xl:px-11 w-1/2 xl:w-1/2">
              {i18n.t('home.experiences.second')}
            </h3>
          </div>

          <div className="bg-cardGray py-12 flex flex-col items-center xl:justify-center px-8 rounded-3xl  xl:row-start-2 xl:col-start-1 xl:row-end-3 xl:col-end-5">
            <SoftwareIcon width={isLarge ? 64 : 32} height={isLarge ? 64 : 32} />
            <h3 className="text-xl text-main text-center font-semibold mt-4 xl:text-3xl xl:px-11 xl:w-2/3 xl:mt-20">
              {i18n.t('home.experiences.third.title')}
            </h3>
            <p className="mt-4 xl:mt-5 px-2 xl:px-8 text-center w-full xl:w-3/5">
              {i18n.t('home.experiences.third.subtitle')}
            </p>
            <Link
              to="software"
              className="border  px-6 py-3 border-black border-opacity-20 rounded-full text-main mt-16 transition hover:bg-gray-300"
            >
              {i18n.t('common.updateSystem')}
            </Link>
          </div>

          <div className="bg-cardGray xl:bg-beige py-12 flex flex-col items-center px-8 rounded-3xl  xl:row-start-2 xl:col-start-5 xl:row-end-3 xl:col-end-7">
            <h3 className="text-xl text-main text-center font-semibold mt-4 xl:text-3xl xl:px-11">
              {i18n.t('home.experiences.fourth.title')}
            </h3>
            <p className="mt-4 px-8 text-center">
              {isLarge ? i18n.t('home.experiences.fourth.subtitle') : i18n.t('home.experiences.fourth.subtit')}
            </p>
            <Link to="/how-to-use" className="text-linkColor my-6 flex items-center">
              <span className="mr-2 xl:text-linkColor">{i18n.t('common.learnMore')}</span>{' '}
              <Caret className="stroke-current text-linkColor xl:text-linkColor icon" width={10} height={10} />
            </Link>

            <img src={LearnMoreAbstraction} className={`-mt-4 ${between ? 'w-64' : 'w-80'}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperiencesSection;
