import { default as dayjs } from 'dayjs';
import React, { MouseEventHandler } from 'react';
import calendar from '../../../assets/images/Software/calendar.svg';
import infoSquare from '../../../assets/images/Software/info-square.svg';
import i18n from '../../../i18n';

type ItemProps = {
  image: any;
  name: string;
  latestBuild: string;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  version: string;
};

const Item = ({ image, name, latestBuild, version, onClick }: ItemProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className="w-full h-full flex  items-center py-6
       bg-white mb-4 rounded-xl md:w-full  xl:flex-row cursor-pointer xl:bg-lightGray px-1"
    >
      <img className="max-h-[120px] xl:max-h-[100px] max-w-fit-content" src={image} alt="device image" />
      <div className="flex flex-col ml-4 mt-2">
        <div className="font-extrabold">{name}</div>
        <div className="text-gray-600 my-2 text-sm flex  py-2">
          <img src={calendar} className="w-4 " alt="calendar" />
          <span className="ml-2">
            {i18n.t('software.latestBuild')} {dayjs(latestBuild).format('YYYY/MM/DD')}
          </span>
        </div>
        <div className="text-gray-600 text-sm flex justify-start ">
          <img src={infoSquare} className="w-4" alt="infoSquare" />{' '}
          <span className="ml-2">
            {i18n.t('software.version')} {version}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Item;
