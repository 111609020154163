import { CSSProperties } from 'react';
import ReactPlayer from 'react-player/youtube';

// const VideoPlayer = () => {
//     return (
//         <div className="rounded-t-[1.875rem]">
//         <ReactPlayer url='https://youtu.be/O_4OfD-wmGs'
//        width='100%'
//        height='100%'
//        className="rounded-t-[1.875rem]"
//        style={{borderTopLeftRadius: "1.875rem", borderTopRightRadius: "1.875rem"}}
//         />
//       </div>
//     )
// }

// export default VideoPlayer;

type VideoProps = {
  url: string;
  width: string;
  height: string;
  style?: CSSProperties;
  className?: string;
  playing?: boolean;
};

const VideoPlayer: React.FC<VideoProps> = ({ url, height, width, style, className, playing }) => {
  return (
    <div className={`rounded-t-[1rem] w-full ${className}`}>
      <ReactPlayer
        playing={playing}
        url={url}
        width={width}
        height={height}
        style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', overflow: 'hidden', ...style }}
      />
    </div>
  );
};

export default VideoPlayer;
