import { Link } from 'react-router-dom';
import { ReactComponent as Globe } from '../../../assets/icons/globe.svg';
import { ReactComponent as Hand } from '../../../assets/icons/hand.svg';
import i18n from '../../../i18n';

type Props = {
  buttonClassName?: string;
};
function GetInTouchSection({ buttonClassName }: Props): JSX.Element {
  return (
    <div className="flex flex-col xl:flex-row justify-center items-center px-6 xl:px-2 bg-white h-full ">
      <div className="container xl:mt-44 mt-20">
        <div className="flex flex-col xl:justify-center xl:flex-1 mt-16">
          <Globe className=" self-center" />
          <p className="mt-4  self-center ">{i18n.t('getInTouchSection.getInTouch')}</p>
          <h3 className="text-lg font-semibold text-center mt-4  sm:w-9/12 md:w-7/12 xl:w-7/12 mx-auto">
            {i18n.t('getInTouchSection.alwaysAvailable')}
          </h3>

          <Link
            to="/contact"
            className={`rounded-3xl p-3 bg-brand text-white font-medium text-xs w-auto my-8 px-8 py-3 md:text-base self-center md:w-1/4 xl:w-1/6  text-center  transition hover:bg-red-700 ${buttonClassName} `}
          >
            {i18n.t('getInTouchSection.sayHello')}
          </Link>
          <Hand className="self-center" />
        </div>
      </div>
    </div>
  );
}
export default GetInTouchSection;
