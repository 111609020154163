import { isRtl } from '../../..';
import i18n from '../../../i18n';
import GetInTouchSection from '../Home/GetInTouchSection';

function AboutUs(): JSX.Element {
  return (
    <div className="flex flex-col justify-center items-center mb-12 md:mb-24 xl:mb-[16rem] ">
      <div className="w-full bg-brand text-white font-medium text-sm xl:text-xl flex flex-col justify-center items-center h-80 md:h-96 xl:">
        <div className="p-6 mt-6">
          <div
            className="bg-white px-5 py-7 flex flex-col justify-center items-center text-center md:px-8 md:py-5 xl:px-8 xl:py-5"
            style={{ borderRadius: '2.75rem' }}
          >
            <h1 className="text-brand text-4xl font-semibold mb-2 md:text-5xl md:mb-0 xl:text-5xl xl:mb-0">8</h1>
            <h1 className="text-brand md:text-sm xl:text-sm"> {i18n.t('aboutUs.years')}</h1>
          </div>
        </div>
        <div className="text-center p-7 xl:w-[490px]">
          <p> {i18n.t('aboutUs.subtitle')}</p>
        </div>
      </div>
      <div className="bg-gray-200 flex flex-col m-4 rounded-3xl p-8 mt-12 justify-start items-start md:flex-row md:justify-between md:items-center md:px-10 md:py-8 md:mt-16 md:container xl:flex-row xl:justify-between xl:items-center xl:px-20 xl:py-24 xl:mt-24 xl:container">
        <div className="flex flex-col justify-start items-start">
          <p className="w-full text-main text-xs font-semibold mb-4"> {i18n.t('aboutUs.companyInfo')}</p>
          <h1 className=" text-4xl font-semibold text-gray-900 mb-6 md:w-[70%] xl:text-5xl lg:w-1/2 xl:w-1/2">
            {i18n.t('aboutUs.missionApproach.title')}
          </h1>
        </div>
        <p className=" text-main font-light  lg:w-3/6 xl:w-2/6 xl:text-left text-base xl:mr-24 md:w-3/5">
          {i18n.t('aboutUs.missionApproach.description')}
        </p>
      </div>

      <div
        className="relative bg-value-mobile md:bg-value-desktop xl:bg-value-desktop bg-center bg-cover md:bg-cover xl:bg-cover h-screen xl:h-[38rem] md:h-[38rem] bg-no-repeat  flex flex-col m-4 rounded-3xl p-8 mt-12 justify-start items-start  md:px-10 md:py-8 md:mt-16 md:container  xl:mt-24 xl:container "
        style={{
          //backgroundImage: `url(${ValueImage})`,
          height: '38rem',
          direction: 'ltr',
          //backgroundSize: 'cover',
          //backgroundRepeat: "no-repeat"
          //backgroundAttachment: "fixed",
        }}
      >
        <div
          className="flex flex-col w-full pt-8 justify-start items-start xl:w-2/5 xl:pt-24  md:w-2/5 md:mt-10 xl:mt-0 xl:pl-20"
          dir={isRtl ? 'rtl' : 'ltr'}
        >
          <h1 className={`text-4xl font-semibold text-gray-900 mb-6`}> {i18n.t('aboutUs.ourValues.title')}</h1>
          <p className="w-full text-main text-lg font-light ">{i18n.t('aboutUs.ourValues.description')}</p>
        </div>
      </div>
      <GetInTouchSection buttonClassName="!w-auto" />
    </div>
  );
}

export default AboutUs;
