import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-use';
import AboutUs from '../components/pages/AboutUs/AboutUs';
import Contact from '../components/pages/Contact/Contact';
import Home from '../components/pages/Home/Home';
import HowToUse from '../components/pages/HowToUse/HowToUse';
import Product from '../components/pages/Product/Product';
import ProductDetails from '../components/pages/ProductDetails/ProductDetails';
import ItemDetails from '../components/pages/Software/ItemDetails';
import Software from '../components/pages/Software/Software';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/UI/Footer/Footer';
import Navigation from '../components/UI/Navigation/Navigation';
import './App.css';

type Props = {
  isRtl: boolean;
};

function App(props: Props): JSX.Element {
  const { i18n } = useTranslation();
  const location = useLocation();

  const isHome = location.pathname === '/';

  if (isHome) {
    document.body.style.backgroundColor = '#000';
  } else {
    document.body.style.backgroundColor = '#fff';
  }

  useEffect(() => {
    dayjs.locale(i18n.language);
  });

  return (
    <div className={`App lang-${i18n.language} `} dir={props.isRtl ? 'rtl' : 'ltr'}>
      <Router>
        <Navigation />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/software">
            <Software />
          </Route>
          <Route exact path="/software/device/:id">
            <ItemDetails />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/how-to-use">
            <HowToUse />
          </Route>
          <Route path="/product/:id">
            <ProductDetails />
          </Route>
          <Route path="/product">
            <Product />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
