import { Helmet } from 'react-helmet';
import i18n from '../i18n';

type SEOProps = {
  title: string;
  description: string;
  keywords: string[];
};
const SEO = ({ title, description, keywords }: SEOProps): JSX.Element => (
  <Helmet
    htmlAttributes={{
      lang: i18n.language,
    }}
    title={title}
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content: keywords.join(', '),
      },
    ]}
  />
);

export default SEO;
