import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { queries } from '../../../devices';
import i18n from '../../../i18n';
type RelatedProductProps = {
  id: string;
  title: string;
  image: string;
  isNew: boolean;
};

const RelatedProduct = ({ id, title, image, isNew }: RelatedProductProps): JSX.Element => {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });
  return (
    <Link className="relative xl:h-full " to={`/product/${id}`}>
      {isLarge && (
        <div className="absolute top-2 left-7">
          <span className={`py-5 font-bold text-brand hidden ${isNew && 'inline'} text-center`}>
            {i18n.t('common.new')}
          </span>
        </div>
      )}
      <div className="flex flex-col justify-center text-center py-10 xl:bg-lightGray  xl:rounded-xl xl:h-full px-8">
        <img className="object-contain xl:mt-5 w-48 h-32" src={image} alt="feature" />
        <span className={`py-5 font-bold text-brand ${isNew ? 'xl:inline' : 'xl:hidden'} absolute top-2 left-10`}>
          {i18n.t('common.new')}
        </span>
        <h3 className="text-xl font-bold xl:my-5 xl:max-w-[200px] break-words min-h-[40px]">{title}</h3>
        {isLarge && (
          <Link
            to={`/product/${id}`}
            className=" justify-self-center self-center items-center hidden xl:flex  transition mt-10 text-linkColor hover:text-linkColorHover text-sm font-semibold"
          >
            <span className="mr-3  ">{i18n.t('common.learnMore')}</span>
            <Caret className="icon stroke-current text-brand" />
          </Link>
        )}
      </div>
    </Link>
  );
};

export default RelatedProduct;
