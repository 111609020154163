import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { ReactComponent as Email } from '../../../assets/icons/email.svg';
import { ReactComponent as ContactIllustration } from '../../../assets/icons/illustrations/contact-illustration.svg';
import { ReactComponent as LocationPin } from '../../../assets/icons/location-pin.svg';
import { ReactComponent as Logo } from '../../../assets/icons/logo-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social-media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/social-media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/social-media/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/social-media/youtube.svg';
import { ReactComponent as Telephone } from '../../../assets/icons/telephone-call.svg';
import i18n from '../../../i18n';
import { usePostContactMutation } from '../../../services/api';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import { Loader } from '../../UI/Loader';

type FormData = {
  name: string;
  message: string;
  email: string;
};
function Contact(): JSX.Element {
  const [postContact, { isLoading, isSuccess, isError, data }] = usePostContactMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const onSubmit = async (data: FormData) => {
    postContact(data);
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-full bg-brand text-white font-medium text-sm md:text-lg xl:text-xl flex flex-col justify-center items-center h-96 md:h-96 xl:h-[500px]">
        <div className="p-6 mt-0">
          <Logo className="fill-current text-white" width={70} height={70} />
        </div>
        <div className="text-center container p-7 xl:w-2/6">
          <p> {i18n.t('contact.title')}</p>
        </div>
      </div>

      <div className="flex flex-col justify-start bg-lightGray w-full items-start p-6 xl:container xl:rounded-3xl xl:relative xl:-top-20 xl:z-50">
        <div className="container flex flex-col justify-start items-start mt-20  mb-8 w-full xl:flex-row xl:justify-between xl:w-full xl:py-0 xl:px-24">
          <div className="w-full ">
            <h1 className=" text-4xl font-semibold text-gray-900 mb-6"> {i18n.t('contact.letsTalk')}</h1>
            <p className="w-full text-main text-base font-light mb-8  xl:w-full">{i18n.t('contact.description')}.</p>

            <div className="flex flex-col justify-start items-start w-full">
              <form className="w-full mb-24" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-8 w-full">
                  <div className="mb-2">
                    <label className="text-main text-base">{i18n.t('contact.form.fullName.label')}</label>
                  </div>
                  <Input
                    placeholder={i18n.t('contact.form.fullName.placeholder')}
                    {...register('name', { required: 'Full name is required' })}
                    name="name"
                    className="p-4 rounded-xl xl:w-full  placeholder-gray-300::placeholder !text-sm"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <p className="text-red-600 text-sm mt-2">{message}</p>}
                  />
                </div>
                <div className="mb-8">
                  <div className="mb-2">
                    <label className="text-main text-base"> {i18n.t('contact.form.email.label')}</label>
                  </div>
                  <Input
                    placeholder={i18n.t('contact.form.email.placeholder')}
                    {...register('message', { required: 'Message is required' })}
                    {...register('email', { required: 'Email is required' })}
                    name="email"
                    className="p-4  rounded-xl xl:w-full text-base !text-sm"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => <p className="text-red-600 text-sm mt-2">{message}</p>}
                  />
                </div>
                <div className="mb-8">
                  <div className="mb-2">
                    <label className="text-main text-base"> {i18n.t('contact.form.message.label')}</label>
                  </div>
                  <textarea
                    className="bg-white  !text-sm border-solid p-4 h-48 w-full border-gray-300 rounded-xl placeholder-gray-300::placeholder  text-start focus:outline-none xl:w-full"
                    style={{ borderWidth: '1px' }}
                    placeholder={i18n.t('contact.form.message.placeholder')}
                    {...register('message', { required: 'Message is required' })}
                    name="message"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => <p className="text-red-600 text-sm mt-2">{message}</p>}
                  />
                </div>
                <Button
                  className={`hover:bg-red-700 disabled:opacity-70 hover:bg-brand text-base text-center transition${
                    isLoading && 'flex items-center justify-center'
                  }`}
                  type="submit"
                >
                  {isLoading ? <Loader isLoading={true} stroke="white" /> : i18n.t('contact.form.submitButton')}
                </Button>

                {isSuccess && data?.success && data?.message && (
                  <p className="border border-green-600 bg-green-100 text-green-800 rounded-lg p-4 mt-4">
                    {data?.message}
                  </p>
                )}
                {isError && (
                  <p className="border border-red-600 bg-red-100 text-red-800 rounded-lg p-4 mt-4">
                    {' '}
                    {i18n.t('common.errorOccured')}.
                  </p>
                )}
              </form>
            </div>
          </div>
          <div className=" w-full flex flex-col justify-start items-start xl:ml-8 xl:w-full xl:items-center">
            <div className="hidden md:hidden xl:block xl:mb-12">
              <ContactIllustration className="mb-12" />
            </div>
            <div className="items-start flex flex-col justify-start xl:flex xl:flex-col xl:justify-start xl:items-start">
              <div className="flex flex-row justify-center items-center mb-4 xl:items-start">
                <Email className="w-5 h-5 mr-3" />

                <p className="text-main text-base" dir="ltr">
                  info@mediastar.co
                </p>
              </div>
              <div className="flex flex-row justify-center items-center mb-4">
                <Telephone className="w-5 h-5 mr-3" />
                <p className="text-main text-base" dir="ltr">
                  0770 449 6003 - 0770 449 6014
                </p>
              </div>
              <div className="flex flex-row justify-center items-center mb-4">
                <LocationPin className="w-5 h-5 mr-3" />
                <p className="text-main text-base" dir="ltr">
                  Nishtiman Bazar, Erbil, Iraq
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-start items-start mt-8 mb-12 xl:self-center">
              <a href="https://www.facebook.com/Mediastarofficial/" className="mr-4" target="_blank" rel="noreferrer">
                <FacebookIcon className="text-linkColor hover:text-linkColorHover fill-current w-5 h-5" />
              </a>
              <a href="https://www.instagram.com/mediastariraq/" className="mr-4" target="_blank" rel="noreferrer">
                <InstagramIcon className="text-linkColor hover:text-linkColorHover fill-current w-5 h-5" />
              </a>
              <a href="https://t.me/MediaStar" className="mr-4" target="_blank" rel="noreferrer">
                <TwitterIcon className="text-linkColor hover:text-linkColorHover fill-current w-5 h-5" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <YoutubeIcon className="text-linkColor hover:text-linkColorHover fill-current w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
