import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { queries } from '../../../devices';
import { getValueFromLang } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetFeaturedProductsQuery } from '../../../services/api';
import { Product as ProductType } from '../../../types/Product';
import Product from './Product';

function DevicesSection(): JSX.Element {
  const { data: featuredProducts, error, isLoading } = useGetFeaturedProductsQuery('');

  const getDeviceTextColor = (index: number): string => {
    if (index === 0) return 'text-msRed';
    else if (index === 1) return 'text-msBeige';
    else if (index === 2) return 'text-msOrange';
    else if (index === 3) return 'text-msGreen';

    return '';
  };
  const isLarge = useMediaQuery({
    query: queries.xl,
  });

  return (
    <Element
      className="flex flex-col xl:flex-row justify-center items-center px-6 xl:px-2 bg-black h-auto  mt-28 xl:mt-0 xl:pt-6 xl:pb-0 pb-12"
      name="devicesSection"
    >
      <div className="flex flex-col w-full items-center xl:flex-1">
        <h2 className="text-white text-3xl font-semibold xl:text-5xl text-center xl:text-left">
          {i18n.t('home.devicesSection.title')}
        </h2>
        <Link to="/product" className="text-linkColor my-4 flex items-center">
          <span className="mr-2 xl:text-brand xl xl:hover:text-red-700 transition">
            {i18n.t('home.devicesSection.explore')}
          </span>{' '}
          <Caret className="stroke-current text-linkColor xl:text-brand icon" width={10} height={10} />
        </Link>

        <div className="grid grid-cols-1 xl:grid-cols-12 w-full gap-x-0 gap-y-20 xl:gap-y-0  mt-20 place-content-center ">
          {featuredProducts !== undefined && featuredProducts?.length > 0
            ? featuredProducts?.map((featuredProduct: ProductType, index: any) => {
                // row-start, col-start, row-end col-end
                //  .div1 { grid-area: 1 / 2 / 2 / 7; }
                //  .div2 { grid-area: 1 / 8 / 2 / 13; }
                //  .div3 { grid-area: 2 / 2 / 3 / 7; }
                //  .div4 { grid-area: 2 / 8 / 3 / 13; }

                let itemPosition = ``;
                let isEnd = false;
                switch (index) {
                  case 0:
                    itemPosition = 'row-start-1 col-start-2 row-end-2 col-end-7';
                    isEnd = false;
                    break;
                  case 1:
                    itemPosition = 'row-start-1 col-start-8 row-end-2 col-end-13';
                    isEnd = true;
                    break;
                  case 2:
                    itemPosition = 'row-start-2 col-start-2 row-end-3 col-end-7';
                    isEnd = false;
                    break;
                  case 3:
                    itemPosition = 'row-start-2 col-start-8 row-end-3 col-end-13';
                    isEnd = true;
                    break;
                  default:
                    break;
                }

                return (
                  <Product
                    key={featuredProduct._id}
                    image={featuredProduct?.images.length > 0 ? featuredProduct.images[0] : ''}
                    name={getValueFromLang(featuredProduct.name)}
                    id={featuredProduct._id}
                    description={getValueFromLang(featuredProduct.description)}
                    features={featuredProduct.features}
                    nameClassName={getDeviceTextColor(index)}
                    className={isLarge ? itemPosition : ''}
                    isEnd={isEnd}
                  />
                );
              })
            : i18n.t('common.noProductsFound')}
        </div>
      </div>
    </Element>
  );
}

export default DevicesSection;
