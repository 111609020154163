import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoDark } from '../../../assets/icons/logo-full-dark.svg';
import { ReactComponent as LogoLight } from '../../../assets/icons/logo-full-light.svg';
import i18n from '../../../i18n';
import Dropdown from '../Dropdown';
import { languageItems } from './Navigation';

type Props = {
  isCurrentRouteHome: boolean;
};
function DesktopNavigation({ isCurrentRouteHome }: Props): JSX.Element {
  const properties = {
    animate: isCurrentRouteHome ? { y: 0, opacity: 1 } : { y: 0, opacity: 1 },
    initial: isCurrentRouteHome ? { y: '-190px', opacity: 0 } : { y: 0, opacity: 1 },
  };

  return (
    <motion.nav
      className={`py-4 ${
        isCurrentRouteHome ? 'bg-black border-none' : 'bg-white'
      } z-20 shadow-navigation  border border-gray-200 border-opacity-50`}
      animate={properties.animate}
      initial={properties.initial}
      transition={{ duration: 2, ease: 'easeInOut' }}
    >
      <div className="container mx-auto flex items-center h-16 justify-center ">
        <NavLink to="/" activeClassName="navigation-link-active">
          {isCurrentRouteHome ? <LogoDark className="flex" /> : <LogoLight className="flex" />}
        </NavLink>

        <ul className="flex flex-shrink flex-grow xl:ml-20">
          <li>
            <NavLink
              to="/product"
              activeClassName="navigation-link-active"
              className={`navigation-link ${isCurrentRouteHome ? 'text-gray-200' : 'text-main'}`}
            >
              {i18n.t('navigation.product')}
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/software"
              activeClassName="navigation-link-active"
              className={`navigation-link ${isCurrentRouteHome ? 'text-gray-200' : 'text-main'}`}
            >
              {i18n.t('navigation.software')}
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/how-to-use"
              activeClassName="navigation-link-active"
              className={`navigation-link ${isCurrentRouteHome ? 'text-gray-200' : 'text-main'}`}
            >
              {i18n.t('navigation.howToUse')}
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/about-us"
              activeClassName="navigation-link-active"
              className={`navigation-link ${isCurrentRouteHome ? 'text-gray-200' : 'text-main'}`}
            >
              {i18n.t('navigation.aboutUs')}
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/contact"
              activeClassName="navigation-link-active"
              className={`navigation-link ${isCurrentRouteHome ? 'text-gray-200' : 'text-main'}`}
            >
              {i18n.t('navigation.contact')}
            </NavLink>
          </li>
        </ul>

        <div>
          <Dropdown
            selectedItemKey={i18n.language}
            getSelectedKey={(language) => {
              window.location.reload();
              i18n.changeLanguage(language);
            }}
            hasIcon={true}
            items={languageItems}
            dropdownButtonClassName={`transition ${
              isCurrentRouteHome
                ? 'transition bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover'
                : 'bg-white transition'
            }`}
            dropdownClassName="w-40"
            dropdownItemClassName={`${
              isCurrentRouteHome
                ? 'transition bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover'
                : 'bg-white transition'
            }`}
            dropdownItemsClassName={`${
              isCurrentRouteHome
                ? 'bg-dropdownGray text-gray-300 border-none hover:bg-dropdownGrayHover transition'
                : 'bg-white transition'
            }`}
          />
        </div>
      </div>
    </motion.nav>
  );
}

export default DesktopNavigation;
