import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/icons/logo-full-light.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social-media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/social-media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/social-media/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/social-media/youtube.svg';
import i18n from '../../../i18n';

function Footer(): JSX.Element {
  return (
    <footer className="bg-lightGray text-sm">
      <div className="container px-6 py-8 flex flex-col xl:py-14">
        <div className="flex flex-col xl:flex-row xl:items-center xl:justify-between">
          <Logo className="hidden xl:block " width="174px" height="28px" />
          <ul className="list-none text-main text-base flex flex-col xl:flex-row xl:flex-2">
            <li className="py-4 border-b-2 xl:border-b-0 border-gray-200 xl:ml-10  text-sm">
              <Link to="/product" className="hover:text-gray-500 transition">
                {i18n.t('footer.mediaStarProducts')}
              </Link>
            </li>
            <li className="py-4 border-b-2 xl:border-b-0 border-gray-200  xl:ml-10  text-sm">
              <Link to="/software" className="hover:text-gray-500 transition">
                {i18n.t('footer.software')}
              </Link>
            </li>
            <li className="py-4 border-b-2 xl:border-b-0 border-gray-200  xl:ml-10  text-sm">
              <Link to="/how-to-use" className="hover:text-gray-500 transition">
                {i18n.t('footer.howToUse')}
              </Link>
            </li>
            <li className="py-4 border-b-2 xl:border-b-0 border-gray-200  xl:ml-10  text-sm">
              <Link to="/about-us" className="hover:text-gray-500 transition">
                {i18n.t('footer.aboutUs')}
              </Link>
            </li>
            <li className="py-4 border-b-2 xl:border-b-0 border-gray-200  xl:ml-10  text-sm">
              <Link to="/contact" className="hover:text-gray-500 transition">
                {i18n.t('footer.contact')}
              </Link>
            </li>
          </ul>
        </div>
        <hr className="mt-8 hidden xl:block text-gray-200" />
        <div className="flex flex-col xl:flex-row xl:items-center xl:justify-start xl:mt-8">
          <div className="mt-8 flex flex-col xl:flex-row flex-1 xl:mt-0 items-center">
            <p className="text-gray-600 text-sm">
              {i18n.t('copyright')} © 2021 Mediastar. {i18n.t('allRightsReserved')}
            </p>
            <span
              className="bg-gray-500 rounded-full ml-5"
              style={{
                height: '8px',
                width: '8px',
                backgroundColor: '#D8D8D8',
                borderRadius: '50%',
                display: 'inline-block',
              }}
            >
              &nbsp;
            </span>
            <p className="font-light text-main hidden xl:flex ml-5 mr-4 font-body" dir="ltr">
              Designed by&nbsp;
              <a className="font-normal" href="https://mediastar.co" target="_blank" rel="noreferrer">
                Mediastar
              </a>
              , Developed By&nbsp;
              <a className="font-normal" href="https://mediastar.co" target="_blank" rel="noreferrer">
                Mediastar
              </a>
            </p>
          </div>

          <div className="flex justify-center text-gray-500 mt-8 flex-col items-center xl:flex-row xl:mt-0">
            <p className="xl:hidden">{i18n.t('navigation.stayInTouch')}</p>

            <div className="flex mt-4 xl:mt-0 text-gray-600">
              <a href="https://www.facebook.com/Mediastarofficial/" className="ml-4" target="_blank" rel="noreferrer">
                <FacebookIcon className="fill-current text-gray-400   hover:text-gray-500 transition" />
              </a>
              <a href="https://www.instagram.com/mediastariraq/" className="ml-4" target="_blank" rel="noreferrer">
                <InstagramIcon className="fill-current text-gray-400  hover:text-gray-500 transition" />
              </a>

              <a href="https://t.me/MediaStar" className="ml-4" target="_blank" rel="noreferrer">
                <TwitterIcon className="fill-current text-gray-400  hover:text-gray-500 transition" />
              </a>
              <a href="https://youtube.com" className="ml-4" target="_blank" rel="noreferrer">
                <YoutubeIcon className="fill-current text-gray-400  hover:text-gray-500 transition" />
              </a>
            </div>
            <p className="font-light text-main text-sm mb-4 mt-6 xl:hidden font-body" dir="ltr">
              Designed by{' '}
              <a className="font-normal" href="https://mediastar.co" target="_blank" rel="noreferrer">
                Mediastar
              </a>
              , Developed By{' '}
              <a className="font-normal" href="https://mediastar.co" target="_blank" rel="noreferrer">
                Mediastar
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
