import { useLocation } from 'react-use';
import SEO from '../../SEO';
import DevicesLineupSection from './DevicesLineupSection';
import DevicesSection from './DevicesSection';
import ExperiencesSection from './ExperiencesSection';
import GetInTouchSection from './GetInTouchSection';
import HeroSection from './HeroSection';

function Home(): JSX.Element {
  const location = useLocation();

  const isHome = location.pathname === '/';

  return (
    <div className="bg-black h-full mt-0 overflow-hidden relative">
      <SEO
        title="Mediastar - Home"
        description="The new Mediastar satellite receiver will provide you with the best TV watching experience that will transform your living room into a movie theatre. "
        keywords={['satellite', 'electronic device', 'air conditioner']}
      />
      <HeroSection />
      <DevicesSection />
      <ExperiencesSection />
      <DevicesLineupSection />

      <div className="pb-44 bg-white">
        <GetInTouchSection />
      </div>
    </div>
  );
}

export default Home;
