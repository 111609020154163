import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArabicIcon } from '../../../assets/icons/countries/iraq.svg';
import { ReactComponent as KurdishIcon } from '../../../assets/icons/countries/kurdistan.svg';
import { ReactComponent as EnglishIcon } from '../../../assets/icons/countries/united-kingdom.svg';
import { queries } from '../../../devices';
import { DropdownItemType } from '../Dropdown';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

export const languageItems: DropdownItemType[] = [
  {
    key: 'en',
    text: 'English',
    icon: EnglishIcon,
  },
  {
    key: 'ar',
    text: 'Arabic',
    icon: ArabicIcon,
  },
  {
    key: 'ku',
    text: 'Kurdish',
    icon: KurdishIcon,
  },
];

function Navigation(): JSX.Element {
  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  const location = useLocation();

  return (
    <>
      {!isLarge ? (
        <MobileNavigation isCurrentRouteHome={location.pathname === '/'} />
      ) : (
        <DesktopNavigation isCurrentRouteHome={location.pathname === '/'} />
      )}
    </>
  );
}

export default Navigation;
