import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../assets/icons/caret.svg';
import { ReactComponent as Illustration } from '../../../assets/icons/illustrations/how-to-use.svg';
import { ReactComponent as ProblemIllustration } from '../../../assets/icons/illustrations/problem.svg';
import { queries } from '../../../devices';
import { getValueFromLang, getYoutubeId } from '../../../helpers';
import i18n from '../../../i18n';
import { useGetFeaturedTutorialQuery, useGetTutorialsQuery } from '../../../services/api';
import { Tutorial } from '../../../types/Tutorial';
import { Loader } from '../../UI/Loader';
import VideoPlayer from './VideoPlayer';

type Video = {
  id: string;
  title: string;
  url: string;
  thumbnailUrl: string;
  description: string;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '500px',
    width: '800px',
    border: 'none',
    padding: '0',
    background: 'rgba(0,0,0,0.8)',
  },
  overlay: {
    background: 'rgba(0,0,0,0.8)',
  },
};
function HowToUse(): JSX.Element {
  const query = queryString.parse(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [tutorials, setTutorials] = useState<Tutorial[]>([]);

  const {
    isLoading,
    isError,
    isFetching,
    isSuccess,
    data: responseData,
  } = useGetTutorialsQuery(
    {
      page: currentPageNumber,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (responseData !== undefined) {
      setTutorials([...tutorials, ...responseData?.result]);
    }
  }, [responseData?.result]);

  const { isLoading: isFeaturedTutorialLoading, data: featuredVideo } = useGetFeaturedTutorialQuery('');
  const {
    count,
    currentPage,
    limit,
    result: videos,
  } = responseData !== undefined ? responseData : { count: 0, currentPage: 0, limit: 0, result: [] };

  const isLarge = useMediaQuery({
    query: queries.lg,
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const play = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const foundedVideo = responseData?.result.filter((tutorial) => tutorial._id === query?.videoId);

    if (foundedVideo !== undefined && foundedVideo?.length > 0) {
      openModal(foundedVideo[0].videoUrl);
    }
  }, [query?.videoId, responseData?.result]);

  const openModal = (url: string) => {
    setIsModalOpen(true);
    setUrl(url);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLoadMore = () => {
    setCurrentPageNumber(currentPageNumber + 1);
  };
  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Devices Updates" style={customStyles}>
        <VideoPlayer url={url} height="100%" width="100%" className="h-full p-0 bg-transparent" />
      </Modal>

      <div className="flex flex-col justify-center items-center">
        <div className="bg-lightGray flex flex-col justify-center items-center w-full rounded-br-[2rem] rounded-bl-[2rem] md:rounded-br-none md:rounded-bl-none xl:rounded-br-none xl:rounded-bl-none">
          <div className="container  w-full md:py-16 xl:py-16 flex flex-col justify-center items-center xl:flex-row-reverse xl:justify-between xl:items-end md:flex-row-reverse md:justify-between md:items-end">
            <div className=" flex justify-center items-center w-full py-12 md:py-0  xl:py-0">
              <Illustration className="xl:h-[60%] xl:w-[60%] md:h-[100%]  md:w-[100%]" />
            </div>
            <div className="mb-12 md:mb-0 xl:mb-0 flex flex-col justify-center items-center xl:justify-start xl:items-start md:justify-start md:items-start w-full container xl:w-[50%] md:mr-10">
              <h1 className="text-main text-3xl font-semibold xl:text-4xl"> {i18n.t('howToUse.title')}</h1>
              <p className="text-base hidden md:block xl:block md:mt-8 xl:mt-8">
                {i18n.t('howToUse.subtitle')}

                <Link to="/contact" className="text-linkColor hover:text-linkColorHover  underline transition">
                  {i18n.t('navigation.contactUs')}
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mb-24 md:hidden xl:hidden px-6">
          {tutorials !== undefined &&
            tutorials?.map((video: Tutorial) => {
              return (
                <div
                  className=" bg-white border-solid border-gray-300 border flex flex-col m-4 rounded-2xl mb-4  mt-12 justify-start items-start md:flex-row md:justify-between md:items-center md:px-10 md:py-8 md:mt-16 md:container xl:flex-row xl:justify-between xl:items-center xl:px-20 xl:py-24 xl:mt-24 xl:container w-full"
                  key={video?._id}
                  onClick={() => window.open(video.videoUrl, '_newtab')}
                >
                  <VideoPlayer url={video?.videoUrl} width="100%" height="100%" />
                  <h1 className="my-4 ml-4">{getValueFromLang(video?.title)}</h1>
                </div>
              );
            })}
          {count - currentPage * limit > 0 && (
            <button
              className="rounded-3xl p-3 bg-white text-main font-medium text-xs w-auto my-8 px-8 py-3 md:text-base self-center   text-center hover:bg-gray-100 border border-gray-300 disabled:hidden  transition"
              onClick={handleLoadMore}
            >
              {isLoading || isFetching ? <Loader isLoading={true} /> : i18n.t('common.loadMore')}
            </button>
          )}
        </div>

        <div className="mt-12 container w-full hidden md:flex md:flex-col md:justify-start md:items-start xl:flex xl:flex-col xl:justify-start xl:items-start">
          {isFeaturedTutorialLoading ? (
            <div></div>
          ) : (
            <>
              <h1 className="text-xl font-semibold mb-4">{getValueFromLang(featuredVideo?.subtitle)}</h1>

              {featuredVideo && (
                <div className="grid grid-cols-10 w-full h-[400px]">
                  <VideoPlayer
                    url={featuredVideo?.videoUrl}
                    width="100%"
                    height="100%"
                    style={{ borderTopRightRadius: 0, borderBottomLeftRadius: '1rem' }}
                    className="col-start-1 col-end-7"
                    playing={isPlaying}
                  />
                  <div className="bg-[#EEEBDD] rounded-tr-2xl rounded-br-2xl col-start-7 col-end-11 p-6">
                    <div className="flex flex-col justify-between items-start w-full h-full m-0 ">
                      <div className="flex flex-col justify-start items-start ">
                        <h1 className="text-xl font-semibold mb-4 ">{getValueFromLang(featuredVideo?.title)}</h1>
                        <p>{getValueFromLang(featuredVideo?.subtitle)}.</p>
                      </div>
                      <button onClick={play} className="text-main mt-10 flex items-center">
                        <span className="mr-2 xl:text-main">{isPlaying ? 'Pause' : 'Play'}</span>{' '}
                        <Caret className="stroke-current text-main xl:text-main icon" width={10} height={10} />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div className={`grid xl:grid-cols-5 md:grid-cols-3 gap-4 mt-16 ${isFetching && 'opacity-60'}`}>
                  {tutorials !== undefined && tutorials.length > 0 ? (
                    tutorials?.map((video: Tutorial) => {
                      return (
                        <div
                          className="mb-4 justify-start items-start  rounded-2xl cursor-pointer   bg-white"
                          key={video._id}
                          onClick={() => {
                            openModal(video?.videoUrl);
                          }}
                        >
                          <img
                            src={`http://img.youtube.com/vi/${getYoutubeId(video.videoUrl)}/hqdefault.jpg`}
                            className="cover rounded-2xl w-[280px]"
                          />
                          <h1 className="my-4 ml-1">{getValueFromLang(video?.title)}</h1>
                        </div>
                      );
                    })
                  ) : (
                    <h2 className="self-center justify-self-center font-semibold text-xl">There are no tutorials.</h2>
                  )}
                </div>
              </div>
            </>
          )}
          {count - currentPage * limit > 0 && (
            <button
              className="rounded-3xl p-3 bg-white text-main font-medium text-xs w-auto my-8 px-8 py-3 md:text-base self-center md:w-1/4 xl:w-1/6  text-center hover:bg-gray-100 border border-gray-300 disabled:hidden transition "
              onClick={handleLoadMore}
            >
              {isLoading || isFetching ? <Loader isLoading={true} /> : i18n.t('common.loadMore')}
            </button>
          )}

          <div className="md:p-8 md:mb-32 md:mt-24 xl:mb-[16rem] xl:mt-[12rem]  rounded-3xl xl:p-16 bg-[#EEEBDD] w-full hidden md:flex md:flex-row md:justify-between md:items-end xl:flex xl:flex-row xl:justify-between xl:items-end">
            <div className="w-2/5 ml-8 md:w-[40%]">
              <h1 className="md:text-2xl xl:text-3xl font-semibold mb-4 lg:w-[60%] xl:w-[40%]">
                {i18n.t('howToUse.problem.title')}
              </h1>
              <p className="mb-8 text-base">{i18n.t('howToUse.problem.subtitle')}</p>
              <Link to="/contact" className="text-main my-6 flex items-center">
                <span className="mr-2 xl:text-main"> {i18n.t('howToUse.learnMore')}</span>{' '}
                <Caret className="stroke-current text-main xl:text-main icon" width={10} height={10} />
              </Link>
            </div>
            <ProblemIllustration />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToUse;
